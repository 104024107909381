import type {Dimensions} from '@floating-ui/react';
import type {CopyAndResizeData, ResizeData} from '@Panels/resize-panel/resize-panel.types';
import type {PosterModeResize} from '@PosterWhiteboard/poster/poster-mode.class';
import {hideLoading, showLoading} from '@Libraries/loading-toast-library';
import type {PosterTypeObject} from '@PosterWhiteboard/poster-type/poster-type.types';
import {showEditorNotificationMessageGrowl} from '@Components/poster-editor/library/poster-editor-library';

const LOADING_TOAST_DELAY = 300;

export const enum SizeUnits {
  PX = 'pixels',
  IN = 'inches',
  MM = 'millimeters',
  CM = 'centimeters',
  FT = 'feet',
}

export const WORD_TO_UNIT_MAPPING: Record<SizeUnits, string> = {
  [SizeUnits.PX]: 'px',
  [SizeUnits.IN]: 'in',
  [SizeUnits.MM]: 'mm',
  [SizeUnits.CM]: 'cm',
  [SizeUnits.FT]: 'ft',
};

interface SizeRange {
  min: number;
  max: number;
}

export const DIMENSION_RANGE: Record<SizeUnits, SizeRange> = {
  [SizeUnits.PX]: {
    min: 50,
    max: 8500,
  },
  [SizeUnits.IN]: {
    min: 1,
    max: 200,
  },
  [SizeUnits.MM]: {
    min: 20,
    max: 5000,
  },
  [SizeUnits.CM]: {
    min: 2,
    max: 500,
  },
  [SizeUnits.FT]: {
    min: 0.1,
    max: 17,
  },
};

const RESIZE_LOADING_PRIORITY = 11;

export const getWordFromUnits = (unit: string): SizeUnits => {
  switch (unit) {
    case 'in':
      return SizeUnits.IN;
    case 'mm':
      return SizeUnits.MM;
    case 'cm':
      return SizeUnits.CM;
    case 'ft':
      return SizeUnits.FT;
    case 'px':
    default:
      return SizeUnits.PX;
  }
};

const limitDimensions = (width: number, height: number, lowerLimit?: number, upperLimit?: number): Dimensions => {
  const dims: Dimensions = {
    width,
    height,
  };
  if (width >= height) {
    if (upperLimit && width > upperLimit) {
      dims.height = (upperLimit * height) / width;
      dims.width = upperLimit;
    } else if (lowerLimit && height < lowerLimit) {
      dims.width = (lowerLimit * width) / height;
      dims.height = lowerLimit;
    }
  } else if (upperLimit && height > upperLimit) {
    dims.width = (upperLimit * width) / height;
    dims.height = upperLimit;
  } else if (lowerLimit && width < lowerLimit) {
    dims.height = (lowerLimit * height) / width;
    dims.width = lowerLimit;
  }

  return dims;
};

const convertCustomUnitsToPixels = (fWidth: number, fHeight: number, width: number, height: number): Dimensions => {
  const canvasMaxSidePrint = 900;
  let factoredWidth: number;
  let factoredHeight: number;
  const aspectRatio = Math.max(fWidth, fHeight) / Math.min(fWidth, fHeight);
  if (width > height) {
    factoredWidth = canvasMaxSidePrint;
    factoredHeight = factoredWidth / aspectRatio;
  } else {
    factoredHeight = canvasMaxSidePrint;
    factoredWidth = factoredHeight / aspectRatio;
  }
  return {
    width: factoredWidth,
    height: factoredHeight,
  };
};

const customDimensionsToPixels = (width: number, height: number, unit: SizeUnits): Dimensions => {
  if (unit === SizeUnits.PX) {
    return {width: Math.floor(width), height: Math.floor(height)};
  }
  const dims = limitDimensions(width, height, DIMENSION_RANGE[unit].min, DIMENSION_RANGE[unit].max);

  return convertCustomUnitsToPixels(dims.width, dims.height, width, height);
};

const getWidthFromPosterType = (posterType: PosterTypeObject): number => {
  if (posterType.widthInch) {
    return posterType.widthInch;
  }
  if (posterType.widthMm) {
    return posterType.widthMm;
  }
  if (posterType.widthCm) {
    return posterType.widthCm;
  }
  if (posterType.widthFt) {
    return posterType.widthFt;
  }
  return posterType.width;
};

const getHeightFromPosterType = (posterType: PosterTypeObject): number => {
  if (posterType.heightInch) {
    return posterType.heightInch;
  }
  if (posterType.heightMm) {
    return posterType.heightMm;
  }
  if (posterType.heightCm) {
    return posterType.heightCm;
  }
  if (posterType.heightFt) {
    return posterType.heightFt;
  }
  return posterType.height;
};

const validateCustomPosterType = (posterType: PosterTypeObject, units: SizeUnits): PosterTypeObject => {
  const customPosterType = {...posterType};
  const setWidth = getWidthFromPosterType(posterType);
  const setHeight = getHeightFromPosterType(posterType);
  const dimensions = customDimensionsToPixels(setWidth, setHeight, units);
  customPosterType.width = dimensions.width;
  customPosterType.height = dimensions.height;
  return customPosterType;
};

const getUnitsFromCustomPosterType = (customPosterType: PosterTypeObject): SizeUnits => {
  if (customPosterType.widthInch !== 0 || customPosterType.heightInch !== 0) {
    return SizeUnits.IN;
  }
  if (customPosterType.widthMm !== 0 || customPosterType.heightMm !== 0) {
    return SizeUnits.MM;
  }
  if (customPosterType.widthCm !== 0 || customPosterType.heightCm !== 0) {
    return SizeUnits.CM;
  }
  if (customPosterType.widthFt !== 0 || customPosterType.heightFt !== 0) {
    return SizeUnits.FT;
  }
  return SizeUnits.PX;
};

const updatePosterTypeDimensions = (posterType: PosterTypeObject, units: string, width: number, height: number): PosterTypeObject => {
  const customPosterType = {...posterType};
  switch (units) {
    case WORD_TO_UNIT_MAPPING[SizeUnits.IN]:
      customPosterType.widthInch = width;
      customPosterType.heightInch = height;
      break;

    case WORD_TO_UNIT_MAPPING[SizeUnits.CM]:
      customPosterType.widthCm = width;
      customPosterType.heightCm = height;
      break;

    case WORD_TO_UNIT_MAPPING[SizeUnits.MM]:
      customPosterType.widthMm = width;
      customPosterType.heightMm = height;
      break;

    case WORD_TO_UNIT_MAPPING[SizeUnits.FT]:
      customPosterType.widthFt = width;
      customPosterType.heightFt = height;
      break;

    default:
      customPosterType.width = width;
      customPosterType.height = height;
      break;
  }
  return customPosterType;
};

export const resizeToUpdatedDimensions = (data: PosterModeResize): void => {
  const updatedPosterType = data.isCustomDimension
    ? updatePosterTypeDimensions(data.posterType, data.units, parseFloat(String(data.userWidth)), parseFloat(String(data.userHeight)))
    : {...data.posterType};
  resizePosterForResizeData(
    {
      isCustomDimension: data.isCustomDimension,
      posterType: updatedPosterType,
    },
    false
  );
};

export const copyAndResizePoster = async (resizeData: CopyAndResizeData): Promise<void> => {
  if (!resizeData.length) {
    return;
  }

  const poster = window.posterEditor?.whiteboard;
  if (!poster) {
    return;
  }

  resizeData.forEach((data): void => {
    let url;
    if (data.isCustomDimension) {
      if (data.posterType && Object.keys(data.posterType).length > 0) {
        const units = getUnitsFromCustomPosterType(data.posterType);
        const posterWidth = getWidthFromPosterType(data.posterType);
        const posterHeight = getHeightFromPosterType(data.posterType);
        url = window.PMW.util.site_url(`posterbuilder/resizeTo/${poster.hashedID}/${data.posterType.name}/${posterWidth}/${posterHeight}/${WORD_TO_UNIT_MAPPING[units]}`);
      } else {
        url = window.PMW.util.site_url(`posterbuilder/resizeTo/${poster.hashedID}/${poster.type.name}/${poster.userWidth}/${poster.userHeight}/${poster.units}`);
      }
    } else if (data.posterType) {
      url = window.PMW.util.site_url(`posterbuilder/resizeTo/${poster.hashedID}/${data.posterType.name}/${data.isRotated}`);
    }
    window.open(url, '_blank');
  });
};

export const resizePosterForResizeData = (resizeData: ResizeData, undoable = true): void => {
  const poster = window.posterEditor?.whiteboard;
  if (!poster) {
    return;
  }
  const {isCustomDimension, posterType} = resizeData;

  if (!posterType) {
    return;
  }

  if (!posterType.name) {
    return;
  }

  showLoading('resizePoster', {
    key: 'resizePoster',
    priority: RESIZE_LOADING_PRIORITY,
    text: window.i18next.t('pmwjs_resizing_poster'),
    delay: LOADING_TOAST_DELAY,
  });

  if (isCustomDimension) {
    if (posterType && Object.keys(posterType).length > 0) {
      const units = getUnitsFromCustomPosterType(posterType);
      const validatedPosterType = validateCustomPosterType(posterType, units);
      void resizePosterToPosterType(
        {
          ...posterType,
          width: validatedPosterType.width,
          height: validatedPosterType.height,
        },
        undoable
      );
    }
    hideLoading('resizePoster');
    return;
  }
  void resizePosterToPosterType(posterType, undoable);
};

export const resizePosterToPosterType = async (posterType: PosterTypeObject, undoable = true): Promise<void> => {
  const poster = window.posterEditor?.whiteboard;
  if (!poster) {
    return;
  }

  const updatedPoster = poster.resizePoster.getResizedPosterObjectForPosterType(posterType);

  await poster.updateFromObject(
    {
      pages: updatedPoster.pages,
      width: updatedPoster.width,
      height: updatedPoster.height,
      units: WORD_TO_UNIT_MAPPING[getUnitsFromCustomPosterType(posterType)],
      userWidth: getWidthFromPosterType(posterType),
      userHeight: getHeightFromPosterType(posterType),
      type: {
        ...updatedPoster.type,
        name: posterType.name,
        displayName: posterType.displayName ?? '',
        description: posterType.description ?? '',
      },
    },
    {undoable}
  );
  hideLoading('resizePoster');

  if (window.PMW.redux.store.getState().posterEditor.isMobileVariant) {
    showEditorNotificationMessageGrowl(window.i18next.t('pmwjs_resized_successfully'));
  }
};
