import type {ReactElement} from 'react';
import React from 'react';
import {createPortal} from 'react-dom';
import {PageTopBar} from '@Components/poster-editor/components/page-top-bar';
import {Page} from '@PosterWhiteboard/page/page.class';
import {useAppSelector} from '@/hooks';

export function PageOptions(): ReactElement {
  const pagesData = useAppSelector((state) => {
    return state.posterEditor.posterObject?.pages?.pagesHashMap;
  });

  const renderPageTopBar = (): null | Array<ReactElement> => {
    const pageTopBars = [];

    if (!pagesData) {
      return null;
    }

    for (const [, page] of Object.entries(pagesData)) {
      const container = Page.getPrePageHTMLContainerForPageId(page.hashedID);
      if (container) {
        pageTopBars.push(createPortal(<PageTopBar pageId={page.hashedID} />, container));
      }
    }

    return pageTopBars;
  };

  return <>{renderPageTopBar()}</>;
}
