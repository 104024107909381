import type {Poster} from '@PosterWhiteboard/poster/poster.class';
import {hideLoading, showLoading} from '@Libraries/loading-toast-library';
import type {HistoryStates} from '@PosterWhiteboard/poster/poster-history/poster-history.types';
import {HistoryStatePoster} from '@PosterWhiteboard/poster/poster-history/history-state-poster';
import {HistoryStateDrawing} from '@PosterWhiteboard/poster/poster-history/history-state-drawing';

const LOADING_TOAST_DELAY = 300;

export class PosterHistory {
  private currentHistoryIndex = -1;
  private posterHistoryStates: HistoryStates[] = [];
  private poster: Poster;
  private processing = false;

  constructor(poster: Poster) {
    this.poster = poster;
  }

  public addPosterHistory(): void {
    this.posterHistoryStates.length = this.currentHistoryIndex + 1;
    this.posterHistoryStates.push(new HistoryStatePoster(this.poster.toObjectForLoad()));
    this.currentHistoryIndex = this.getMaxHistoryIndex();
    this.poster.redux.updateUndoRedoStates();
  }

  public async addDrawingHistory(): Promise<void> {
    this.posterHistoryStates.length = this.currentHistoryIndex + 1;
    this.posterHistoryStates.push(new HistoryStateDrawing(await this.poster.drawing.getDrawnFabricObjects()));
    this.currentHistoryIndex = this.getMaxHistoryIndex();
    this.poster.redux.updateUndoRedoStates();
  }

  public reset(): void {
    this.posterHistoryStates.push(new HistoryStatePoster(this.poster.toObjectForLoad()));
    this.currentHistoryIndex = this.getMaxHistoryIndex();
    this.poster.redux.updateUndoRedoStates();
  }

  public hasUndo(): boolean {
    return this.currentHistoryIndex > 0;
  }

  public hasRedo(): boolean {
    return this.currentHistoryIndex < this.getMaxHistoryIndex();
  }

  async redo(): Promise<void> {
    if (this.processing) {
      return;
    }

    this.processing = true;
    try {
      showLoading('redoChanges', {delay: LOADING_TOAST_DELAY});
      const historyState = this.posterHistoryStates[this.currentHistoryIndex + 1];
      if (historyState) {
        await this.applyHistoryState(historyState);
        this.currentHistoryIndex += 1;
        this.poster.redux.updateUndoRedoStates();
      }
    } finally {
      this.processing = false;
      hideLoading('redoChanges');
    }
  }

  async undo(): Promise<void> {
    if (this.processing) {
      return;
    }

    this.processing = true;
    try {
      showLoading('undoChanges', {delay: LOADING_TOAST_DELAY});
      const historyState = this.posterHistoryStates[this.currentHistoryIndex - 1];

      if (historyState) {
        await this.applyHistoryState(historyState);
        this.currentHistoryIndex -= 1;
        this.poster.redux.updateUndoRedoStates();
      }
    } finally {
      this.processing = false;
      hideLoading('undoChanges');
    }
  }

  removeDrawingHistoryStates(): void {
    const newPosterHistoryStates = [];
    let newCurrentHistoryIndex = -1;

    for (const [index, posterHistoryState] of this.posterHistoryStates.entries()) {
      if (posterHistoryState instanceof HistoryStatePoster) {
        if (index <= this.currentHistoryIndex) {
          newCurrentHistoryIndex = index;
        }
        newPosterHistoryStates.push(posterHistoryState);
      }
    }

    this.currentHistoryIndex = newCurrentHistoryIndex;
    this.posterHistoryStates = newPosterHistoryStates;
  }

  async applyHistoryState(historyState: HistoryStates): Promise<void> {
    if (historyState instanceof HistoryStatePoster) {
      await this.poster.updateFromObject(historyState.posterLoadObject, {undoable: false, refreshActiveSelection: true});
    } else if (historyState instanceof HistoryStateDrawing) {
      this.poster.drawing.updateCurrentDrawingFromFabricObjects(historyState.drawnFabricObjects);
    }
  }

  private getMaxHistoryIndex(): number {
    return this.posterHistoryStates.length - 1;
  }
}
