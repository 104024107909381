import type {ReactElement} from 'react';
import React from 'react';
import type {BaseControlledListItem} from '@Components/controlled-list/controlled-list.types';
import {Text, TextSize} from '@Components/text';
import {IconSize} from '@Components/icon-v2/icon.types';
import {Checkbox, CHECKBOX_TYPE} from '@Components/checkbox';
import styles from './controlled-list-item-type-6.module.scss';

export interface ControlledListItemType6Props extends BaseControlledListItem {
  textClasses?: string;
  color?: ColorTypes;
  isDisabled?: boolean;
  size?: IconSize;
  checkboxType?: CHECKBOX_TYPE;
}

export enum ColorTypes {
  default = 'default',
  danger = 'danger',
}

export function ControlledListItemType6({
  id = '',
  className = '',
  isSelected = false,
  color = ColorTypes.default,
  size = IconSize.SIZE_ICON_20,
  checkboxType = CHECKBOX_TYPE.DEFAULT,
  isDisabled = false,
  onClick = (): void => {},
  ...props
}: ControlledListItemType6Props): ReactElement {
  const getTextColorClass = (): string => {
    if (color === ColorTypes.default) {
      return 'content-body';
    }
    if (color === ColorTypes.danger) {
      return styles.danger;
    }
    return '';
  };

  return (
    <li
      onClick={(): void => {
        return onClick(id);
      }}
      className={`${styles.listItemContainer} spacing-m-b-2 hover-transition-all radius-2 spacing-p-3 flex-row-justify-between ${className} ${isSelected ? styles.selected : ''} ${
        isDisabled ? `${styles.disabled} disabled` : ''
      }`}
    >
      <div className="flex-center">
        <Checkbox className="spacing-m-r-2" size={size} type={isSelected ? CHECKBOX_TYPE.PRIMARY : checkboxType} isChecked={isSelected} />
        <Text className={`${isDisabled ? 'disabled' : ''} ${getTextColorClass()} ${styles.listText}`} bold={isSelected} val={props.text} size={TextSize.XSMALL} />
      </div>
    </li>
  );
}
