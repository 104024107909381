import type {PayloadAction} from '@reduxjs/toolkit';
import {createSelector, createSlice} from '@reduxjs/toolkit';
import {getSortedKeysOnValue} from '@Utils/object.util';
import {insert} from '@Utils/array.util';
import type {GridItemStorage} from './components/grid-item';
import type {RootState} from '@/store';

/**
 * To allow same selection in multiple grids at a time we have a gridSelectionGroupHashmap in reducer alongside gridsHashmap.
 * gridsHashmap has details of gridItems in it while gridSelectionGroupHashmap has details of gridIds it has along with currently selected items.
 */

interface SetSelectedItemsActionProps {
  gridSelectionGroupId: string;
}

interface ToggleGridItemSelection {
  gridItemId: string;
  gridSelectionGroupId: string;
  select?: boolean;
}

interface AddGridActionProps {
  id: string;
  gridSelectionGroupId?: string;
  maxSelectionNumber?: number;
  items: Array<GridItemStorage>;
}

interface AddLazyGridActionProps {
  id: string;
  gridSelectionGroupId?: string;
  maxSelectionNumber?: number;
}

interface ReloadLazyGridProps {
  gridId: string;
  gridSelectionGroupId: string;
}

interface UpdateMaxSelectionNumberForGridProps {
  gridSelectionGroupId: string;
  maxSelectionNumber: number;
}

interface ClearGridItemsActionProps {
  gridId: string;
}

export interface AddItemsToGridProps {
  id: string;
  items: Array<GridItemStorage>;
  index?: number;
}

interface DeleteGridItemsProps {
  gridId: string;
  gridSelectionGroupId: string;
  itemIdsToDelete: Array<string>;
}

interface SetGridItemsProps {
  id: string;
  gridSelectionGroupId: string;
  items: Array<GridItemStorage>;
}

interface UpdateGridItemProps {
  gridId: string;
  itemId: string;
  changes: Record<string, any>;
}

interface ReplaceGridItemProps {
  gridId: string;
  itemId: string;
  gridItem: GridItemStorage;
}

interface ReplaceAddGridItemProps {
  gridId: string;
  gridItems: Record<string, GridItemStorage>;
}

export type GridStorageTypes = GridStorage | LazyGridStorage;

interface BaseGridStorage {
  items: Array<GridItemStorage>;

  [key: string]: any;
}

type GridStorage = BaseGridStorage;

interface LazyGridStorage extends BaseGridStorage {
  requestNum: number;
}

interface GridsStorage {
  [key: string]: GridStorageTypes;
}

interface GridSelectionGroupStorage {
  /**
   * Key is the id of grid
   */
  gridIdHashmap: Record<string, null>;
  /**
   * 0 value would mean no limits
   */
  maxSelectionNumber: number;
  /**
   * Key is the id of grid item and value is the selction number
   */
  selectedItemIdHashmap: Record<string, number>;
}

interface SelectionGroupHashmap {
  [key: string]: GridSelectionGroupStorage;
}

export interface StateProps {
  gridsHashmap: GridsStorage;
  gridSelectionGroupHashmap: SelectionGroupHashmap;
}

const initialState: StateProps = {
  gridsHashmap: {},
  gridSelectionGroupHashmap: {},
};

export const gridSlice = createSlice({
  name: 'grid',
  initialState,
  reducers: {
    reloadGrid: (state, action: PayloadAction<ReloadLazyGridProps>) => {
      if (!doesGridExistInStore(state, action.payload.gridId)) {
        return;
      }
      unselectItemsInGrid(state, action.payload.gridId, action.payload.gridSelectionGroupId);
      state.gridsHashmap[action.payload.gridId].items = [];

      if (state.gridsHashmap[action.payload.gridId].hasOwnProperty('requestNum')) {
        state.gridsHashmap[action.payload.gridId].requestNum += 1;
      }
    },
    addGrid: (state, action: PayloadAction<AddGridActionProps>) => {
      if (!doesGridExistInStore(state, action.payload.id)) {
        state.gridsHashmap[action.payload.id] = {
          items: action.payload.items,
        };

        addGridToSelecctionGroup(action.payload.id, action.payload?.gridSelectionGroupId, action.payload?.maxSelectionNumber, state);
      }
    },
    addLazyGrid: (state, action: PayloadAction<AddLazyGridActionProps>) => {
      if (!doesGridExistInStore(state, action.payload.id)) {
        state.gridsHashmap[action.payload.id] = {
          items: [],
          requestNum: 1,
        };

        addGridToSelecctionGroup(action.payload.id, action.payload?.gridSelectionGroupId, action.payload?.maxSelectionNumber, state);
      }
    },
    updateMaxSelectionNumberForGrid: (state, action: PayloadAction<UpdateMaxSelectionNumberForGridProps>) => {
      if (state.gridSelectionGroupHashmap[action.payload.gridSelectionGroupId]) {
        state.gridSelectionGroupHashmap[action.payload.gridSelectionGroupId].maxSelectionNumber = action.payload.maxSelectionNumber;
      }
    },
    clearGridItems: (state, action: PayloadAction<ClearGridItemsActionProps>) => {
      state.gridsHashmap[action.payload.gridId].items = [];
    },
    addItemsToGrid: (state, action: PayloadAction<AddItemsToGridProps>) => {
      const existingGridItems = state.gridsHashmap[action.payload.id].items;
      state.gridsHashmap[action.payload.id].items = insert(existingGridItems, action.payload.index ?? existingGridItems.length, action.payload.items);
    },
    addOrReplaceGridItems: (state, action: PayloadAction<ReplaceAddGridItemProps>) => {
      const {items} = state.gridsHashmap[action.payload.gridId];
      if (!items.length) {
        return;
      }
      for (let i = 0; i < Object.keys(action.payload.gridItems).length; i++) {
        if (action.payload.gridItems[items[i].id]) {
          items[i] = action.payload.gridItems[items[i].id];
          delete action.payload.gridItems[items[i].id];
        }
      }
      const newItems = Object.values(action.payload.gridItems);
      if (newItems.length) {
        const existingGridItems = state.gridsHashmap[action.payload.gridId].items;
        state.gridsHashmap[action.payload.gridId].items = insert(existingGridItems, 0, newItems);
      }
    },
    deleteGridItems: (state, action: PayloadAction<DeleteGridItemsProps>) => {
      const {items} = state.gridsHashmap[action.payload.gridId];
      const gridSelectionGroup = state.gridSelectionGroupHashmap[action.payload.gridSelectionGroupId];
      const newItems = [];

      for (let i = 0; i < items.length; i++) {
        if (action.payload.itemIdsToDelete.includes(items[i].id)) {
          unselectGridItem(gridSelectionGroup, items[i].id);
        } else {
          newItems.push(items[i]);
        }
      }
      state.gridsHashmap[action.payload.gridId].items = newItems;
    },
    setGridItems: (state, action: PayloadAction<SetGridItemsProps>) => {
      if (state.gridsHashmap[action.payload.id]) {
        unselectItemsInGrid(state, action.payload.id, action.payload.gridSelectionGroupId);
        state.gridsHashmap[action.payload.id].items = action.payload.items;
      }
    },
    updateGridItem: (state, action: PayloadAction<UpdateGridItemProps>) => {
      const {items} = state.gridsHashmap[action.payload.gridId];
      for (let i = 0; i < items.length; i++) {
        if (items[i].id === action.payload.itemId) {
          items[i] = {
            ...items[i],
            ...action.payload.changes,
          };
        }
      }
    },
    replaceGridItem: (state, action: PayloadAction<ReplaceGridItemProps>) => {
      const {items} = state.gridsHashmap[action.payload.gridId];
      for (let i = 0; i < items.length; i++) {
        if (items[i].id === action.payload.itemId) {
          items[i] = action.payload.gridItem;
        }
      }
    },
    clearSelectedItems: (state, action: PayloadAction<SetSelectedItemsActionProps>) => {
      if (state.gridSelectionGroupHashmap[action.payload.gridSelectionGroupId]) {
        state.gridSelectionGroupHashmap[action.payload.gridSelectionGroupId].selectedItemIdHashmap = {};
      }
    },
    toggleGridItemSelection: (state, action: PayloadAction<ToggleGridItemSelection>) => {
      const currentSelectionNumber = getSelectionNumberForGridItemId(state, action.payload.gridSelectionGroupId, action.payload.gridItemId);
      const gridSelectionGroup = state.gridSelectionGroupHashmap[action.payload.gridSelectionGroupId];

      if (!currentSelectionNumber || action.payload.select) {
        selectGridItem(gridSelectionGroup, action.payload.gridItemId);
      } else {
        unselectGridItem(gridSelectionGroup, action.payload.gridItemId);
      }
    },
  },
});

const getSelectionNumberForGridItemId = (state: StateProps, gridSelectionGroupId: string, gridItemId: string) => {
  return state.gridSelectionGroupHashmap[gridSelectionGroupId].selectedItemIdHashmap[gridItemId];
};

const doesGridExistInStore = (state: StateProps, gridId: string): boolean => {
  return state.gridsHashmap[gridId] !== undefined;
};

const selectGridItem = (gridSelectionGroup: GridSelectionGroupStorage, gridItemId: string): void => {
  let noOfSelectedItems = Object.keys(gridSelectionGroup.selectedItemIdHashmap).length;

  if (gridSelectionGroup.maxSelectionNumber && noOfSelectedItems >= gridSelectionGroup.maxSelectionNumber) {
    unselectGridItem(gridSelectionGroup, getGridItemIdWithMinSelectionNumberForGridSelectionGroup(gridSelectionGroup));
    noOfSelectedItems -= 1;
  }

  gridSelectionGroup.selectedItemIdHashmap[gridItemId] = noOfSelectedItems + 1;
};

const unselectGridItem = (gridSelectionGroup: GridSelectionGroupStorage, gridItemId: string): void => {
  delete gridSelectionGroup.selectedItemIdHashmap[gridItemId];
  updateSelectionNumbersOfItemsInGrid(gridSelectionGroup);
};

const addGridToSelecctionGroup = (gridId: string, gridSelectionGroupId: string | undefined, maxSelectionNumber: number | undefined, state: StateProps): void => {
  const selectionGroupId = gridSelectionGroupId ?? gridId;

  if (!state.gridSelectionGroupHashmap[selectionGroupId]) {
    state.gridSelectionGroupHashmap[selectionGroupId] = {
      gridIdHashmap: {},
      maxSelectionNumber: maxSelectionNumber ?? 0,
      selectedItemIdHashmap: {},
    };
  }
  state.gridSelectionGroupHashmap[selectionGroupId].gridIdHashmap[gridId] = null;
};

const getGridItemIdWithMinSelectionNumberForGridSelectionGroup = (gridSelectionGroup: GridSelectionGroupStorage): string => {
  for (const [gridItemId, selectionNumber] of Object.entries(gridSelectionGroup.selectedItemIdHashmap)) {
    if (selectionNumber === 1) {
      return gridItemId;
    }
  }
  return '';
};

const unselectItemsInGrid = (state: StateProps, gridId: string, gridSelectionGroupId: string): void => {
  const gridSelectionGroup = state.gridSelectionGroupHashmap[gridSelectionGroupId];
  if (gridSelectionGroup) {
    for (const gridItem of state.gridsHashmap[gridId].items) {
      if (gridSelectionGroup.selectedItemIdHashmap[gridItem.id]) {
        delete gridSelectionGroup.selectedItemIdHashmap[gridItem.id];
      }
    }
    updateSelectionNumbersOfItemsInGrid(gridSelectionGroup);
  }
};

const updateSelectionNumbersOfItemsInGrid = (gridSelectionGroup: GridSelectionGroupStorage): void => {
  const selectedItems: Record<string, number> = {};

  for (const [gridItemId, selectionNumber] of Object.entries(gridSelectionGroup.selectedItemIdHashmap)) {
    if (selectionNumber) {
      selectedItems[gridItemId] = selectionNumber;
    }
  }

  const sortedSelectedItemIds = getSortedKeysOnValue(selectedItems);
  for (let i = 0; i < sortedSelectedItemIds.length; i++) {
    gridSelectionGroup.selectedItemIdHashmap[sortedSelectedItemIds[i]] = i + 1;
  }
};

export const {
  reloadGrid,
  replaceGridItem,
  deleteGridItems,
  updateGridItem,
  setGridItems,
  clearGridItems,
  addGrid,
  addLazyGrid,
  updateMaxSelectionNumberForGrid,
  addItemsToGrid,
  clearSelectedItems,
  toggleGridItemSelection,
  addOrReplaceGridItems,
} = gridSlice.actions;
export const baseGridReducer = gridSlice.reducer;

export const getGridItemStorageForId = createSelector(
  [
    (state: RootState) => {
      return state.grids;
    },
    (state: RootState, gridId: string) => {
      return gridId;
    },
    (state: RootState, gridId: string, gridItemId: string) => {
      return gridItemId;
    },
  ],
  (grids, gridId, gridItemId) => {
    const gridItems = grids.gridsHashmap[gridId]?.items;

    if (gridItems) {
      for (const gridItem of gridItems) {
        if (gridItem.id === gridItemId) {
          return gridItem;
        }
      }
    }
    return null;
  }
);

export const getNoOfSelectedItems = createSelector(
  [
    (state: RootState) => {
      return state.grids;
    },
    (state: RootState, gridSelectionGroupId: string) => {
      return gridSelectionGroupId;
    },
  ],
  (grids, gridSelectionGroupId) => {
    const selectedGridItemsHashmap = grids.gridSelectionGroupHashmap[gridSelectionGroupId]?.selectedItemIdHashmap;
    if (!selectedGridItemsHashmap) {
      return 0;
    }

    return Object.keys(selectedGridItemsHashmap).length;
  }
);

export const getSelectedGridItems = createSelector(
  [
    (state: RootState) => {
      return state;
    },
    (state: RootState, gridSelectionGroupId: string) => {
      return gridSelectionGroupId;
    },
  ],
  (state, gridSelectionGroupId): Array<GridItemStorage> => {
    const selectedGridItemsHashmap = state.grids.gridSelectionGroupHashmap[gridSelectionGroupId]?.selectedItemIdHashmap;
    const gridIdHashmap = state.grids.gridSelectionGroupHashmap[gridSelectionGroupId]?.gridIdHashmap;
    const items = new Array(getNoOfSelectedItems(state, gridSelectionGroupId));

    if (!gridIdHashmap) {
      return [];
    }

    for (const [gridId] of Object.entries(gridIdHashmap)) {
      const gridItems = state.grids.gridsHashmap[gridId]?.items;

      if (!gridItems) {
        continue;
      }

      for (let i = 0; i < gridItems.length; i++) {
        if (gridItems[i].id && selectedGridItemsHashmap[gridItems[i].id]) {
          items[selectedGridItemsHashmap[gridItems[i].id] - 1] = gridItems[i];
        }
      }
    }

    return items;
  }
);

export const getNoOfGridItems = createSelector(
  [
    (state: RootState) => {
      return state;
    },
    (state: RootState, gridID: string) => {
      return gridID;
    },
  ],
  (state, gridID): number => {
    const gridItems = state.grids.gridsHashmap[gridID]?.items;
    return gridItems ? gridItems.length : 0;
  }
);
