import React from 'react';
import styles from './controlled-list-divider-item.module.scss';

export interface ControlledListDividerItemProps {
  id: string;
  className?: string;
}

export function ControlledListDividerItem({className = '', ...props}: ControlledListDividerItemProps) {
  return <li key={props.id} className={`${className} ${styles.divider}`} />;
}
