import {HistoryState} from '@PosterWhiteboard/poster/poster-history/history-state';
import type {FabricObject} from '@postermywall/fabricjs-2';

export class HistoryStateDrawing extends HistoryState {
  public drawnFabricObjects: FabricObject[];

  public constructor(drawingFabricObjects: FabricObject[]) {
    super();
    this.drawnFabricObjects = drawingFabricObjects;
  }
}
