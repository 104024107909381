import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';

interface StateProps {
  isUserOnline: boolean;
}

const initialState: StateProps = {
  isUserOnline: true,
};

const userOnlineStatusSlice = createSlice({
  name: 'userOnlineStatus',
  initialState,
  reducers: {
    updateIsUserOnline(state, action: PayloadAction<boolean>) {
      state.isUserOnline = action.payload;
    },
  },
});

export const {updateIsUserOnline} = userOnlineStatusSlice.actions;

export const userOnlineStatusReducer = userOnlineStatusSlice.reducer;
