import type {Page} from '@PosterWhiteboard/page/page.class';
import {PAGE_WATERMARK_MODE} from '@PosterWhiteboard/page/page-watermark.class';
import type {ImageFormat, TDataUrlOptions} from '@postermywall/fabricjs-2';

const MAX_IMAGE_FREEBIE_DIMENSION = 2500;
/**
 * Max value for the width or the height on a custom dimension design before which a watermark is shown.
 */
const NO_WATERMARK_ON_CUSTOM_BREAKPOINT = 900;

interface PosterSnapshotOpts {
  format?: ImageFormat;
  scale?: number;
  showPMWWatermark?: boolean;
  mode?: PAGE_WATERMARK_MODE;
  enlargeWatermark?: boolean;
  enableTransparency?: boolean;
  toDataURLOptions?: Partial<TDataUrlOptions>;
}

export class PageSnapshot {
  public page: Page;

  constructor(page: Page) {
    this.page = page;
  }

  public getSnapshot({
    scale = 1,
    format,
    showPMWWatermark = true,
    mode = PAGE_WATERMARK_MODE.DEFAULT,
    enlargeWatermark = false,
    enableTransparency = true,
    toDataURLOptions = {
      enableRetinaScaling: true,
      quality: 1,
    },
  }: PosterSnapshotOpts): string {
    const snapShotFormat: ImageFormat = format ?? (!this.page.isTransparent() || !enableTransparency ? 'jpeg' : 'png');
    let watermarkMode = mode;
    if (mode === PAGE_WATERMARK_MODE.DEFAULT) {
      watermarkMode = this.page.isPremium() ? PAGE_WATERMARK_MODE.LARGE : PAGE_WATERMARK_MODE.DEFAULT;
    }

    this.page.pageWatermark.setWatermark({
      mode: watermarkMode,
      showPMW: showPMWWatermark,
      enlargeWatermark,
    });
    // this.hideGuide();

    if (snapShotFormat === 'jpeg') {
      this.page.background.showWhiteBackgroundForTransparentBackground();
    }

    const bits = this.page.fabricCanvas.toDataURL({
      format: snapShotFormat,
      multiplier: (1 / (this.page.poster.scaling.scale * this.page.fabricCanvas.getRetinaScaling())) * scale,
      ...toDataURLOptions,
    });

    if (snapShotFormat === 'jpeg') {
      this.page.background.showTransparentBackgroundForTransparentBackground();
    }
    // this.showGuide();

    if (watermarkMode !== PAGE_WATERMARK_MODE.DEFAULT || !showPMWWatermark) {
      this.page.pageWatermark.refreshWatermark();
    }

    return bits;
  }

  /**
   * Creates a tailored freebie for the design on the canvas.
   */
  public getFreeDownloadSnapshot(isWebDownload: boolean): string {
    // Do not show watermark for Web Downloads unless it is a custom dimension design with width or height greater than the NO_WATERMARK_ON_CUSTOM_BREAKPOINT constant
    let scaleFactor = 1;
    if (this.page.poster.width > MAX_IMAGE_FREEBIE_DIMENSION || this.page.poster.height > MAX_IMAGE_FREEBIE_DIMENSION) {
      if (this.page.poster.width > this.page.poster.height) {
        scaleFactor = MAX_IMAGE_FREEBIE_DIMENSION / this.page.poster.width;
      } else {
        scaleFactor = MAX_IMAGE_FREEBIE_DIMENSION / this.page.poster.height;
      }
    }

    return this.getSnapshot({
      scale: scaleFactor,
      showPMWWatermark:
        !isWebDownload ||
        (this.page.poster.type.name === 'poster-custom' &&
          (this.page.poster.width > NO_WATERMARK_ON_CUSTOM_BREAKPOINT || this.page.poster.height > NO_WATERMARK_ON_CUSTOM_BREAKPOINT)),
    });
  }
}
