import {isIOS, isMacOs, isSafari} from 'react-device-detect';
import type React from 'react';
import {prependProtocol} from '@Utils/url.util';

/**
 * Variable to cache whether browser supports webp or not so that it is not determined each time
 * @type {boolean|null}
 * @private
 */
let isWebpSupportedCache: boolean | null = null;

/**
 * Variable to cache whether browser supports webm or not so that it is not determined each time
 * @type {boolean|null}
 * @private
 */
let doesBrowserSupportWebmCache: boolean | null = null;

/**
 * Whether the browser supports webp or not
 * @return {boolean}
 */
export const isWebpSupported = (): boolean => {
  if (isWebpSupportedCache === null) {
    const elem = document.createElement('canvas');

    if (elem.getContext && elem.getContext('2d')) {
      // was able or not to get WebP representation
      isWebpSupportedCache = elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    } else {
      // very old browser like IE 8, canvas not supported
      isWebpSupportedCache = false;
    }
  }

  return isWebpSupportedCache;
};

/**
 * Whether the browser supports webm or not
 * @return {boolean}
 */
export const isWebmSupported = (): boolean => {
  if (isSafari || isIOS) {
    return false;
  }
  if (doesBrowserSupportWebmCache === null) {
    const video = document.createElement('video');
    doesBrowserSupportWebmCache = video.canPlayType('video/webm; codecs="vp8, vorbis"') === 'probably';
  }
  return doesBrowserSupportWebmCache;
};

export const isProResSupported = (): boolean => {
  return isMacOs && isSafari;
};

export const isTransparentVideoSupported = (): boolean => {
  return isWebmSupported() || isProResSupported();
};

/**
 * @param {React.RefObject} ref
 * @returns {boolean}
 */
export const isEntirelyInViewport = (ref: React.RefObject<HTMLElement>): boolean => {
  const el = ref.current;

  if (el) {
    const bounding = el.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.right <= (document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth) &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    );
  }

  return false;
};

/**
 * Returns whether the web app is open in a pwa
 * @returns {boolean}
 */
export const isInPWA = (): boolean => {
  const mqStandAlone = '(display-mode: standalone)';
  const nav = navigator as Navigator & {standalone?: boolean};

  return (nav.standalone && nav.standalone) || window.matchMedia(mqStandAlone).matches;
};

/**
 * Returns whether the web app is open in an ios pwa
 * @returns {boolean}
 */
export const isInIOSPWA = (): boolean => {
  return isInPWA() && isIOS;
};

/**
 * Redirects to the given url
 * @param {string} url
 * @param {boolean} showConfirmation
 * @param {boolean} newTab
 */
export function redirectUser(url: string, showConfirmation: boolean, newTab: boolean): void {
  let urlToRedirectTo = url;

  const target = newTab ? '_blank' : '_top';
  urlToRedirectTo = prependProtocol(url);

  if (urlToRedirectTo) {
    if (
      showConfirmation &&
      confirm(
        window.i18next.t('pmwjs_poster_opening_link', {
          site: urlToRedirectTo,
        })
      )
    ) {
      (window.open(urlToRedirectTo, target) as Window).focus();
    } else if (!showConfirmation) {
      (window.open(urlToRedirectTo, target) as Window).focus();
    }
  }
}

/**
 * Mirrored from postermaker/Util.js
 * Handler for when the user stops typing in an input field.
 * @param {jQuery} el
 * @param {function} handler
 * @param {number} [timeout=500] Timeout value in milliseconds after which typing stopped handler will be fired (500 by default).
 * @param {string} [s=null] An optional selector.
 */
export function attachTypingStoppedHandler(el: JQuery, handler: (e: KeyboardEvent) => void, timeout: number = 500, s: string | null = null): void {
  el.on('keypress input paste', s, handleInputChange);

  let inputChangeDelay: NodeJS.Timeout | null = null;

  function handleInputChange(e: any): void {
    if (inputChangeDelay) {
      clearTimeout(inputChangeDelay);
    }

    if (typeof e.which !== 'undefined' && e.which === 13) {
      handler(e);
      return;
    }

    inputChangeDelay = setTimeout(handler.bind(handleInputChange, e), timeout);
  }
}

/**
 * https://stackoverflow.com/a/38089434/16611802
 * @return {string[]} visibilityChange event name and visibilityHidden status
 */
export function getSupportedVisibilityChangeEvent(): [string, string] {
  let visibilityHidden: string = 'hidden';
  let visibilityChange: string = 'visibilitychange';

  // Opera 12.10 and Firefox 18 and later support
  if (typeof document.hidden !== 'undefined') {
    visibilityHidden = 'hidden';
    visibilityChange = 'visibilitychange';
    // @ts-ignore
  } else if (typeof document.mozHidden !== 'undefined') {
    visibilityHidden = 'mozHidden';
    visibilityChange = 'mozvisibilitychange';
    // @ts-ignore
  } else if (typeof document.msHidden !== 'undefined') {
    visibilityHidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
    // @ts-ignore
  } else if (typeof document.webkitHidden !== 'undefined') {
    visibilityHidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  }

  return [visibilityChange, visibilityHidden];
}

export const isTouchDevice = (): boolean => {
  return 'ontouchstart' in window.document;
};

interface Headers {
  [key: string]: string;
}

type XMLHttpResponse = Blob | string | JSON | Document | undefined;

/**
 * Fetches a file from given url as the specified response type
 * @param {string} url
 * @param {string} [responseType="text"]
 * @param {number} [timeout=0] no timeout unless given
 * @param {object} [headers={}]
 * @returns {Promise<*|undefined>} Promise resolving to response of the specified type or
 * undefined in case of error
 */
export const fetchAs = async (url: string, responseType: XMLHttpRequestResponseType = 'text', timeout: number = 0, headers: Headers = {}): Promise<XMLHttpResponse | undefined> => {
  try {
    return await makeGetRequest(url, responseType, headers, timeout);
  } catch (e) {
    console.error(`Failed to fetch ${url} ${e}`);
    return undefined;
  }
};

/**
 * Makes a GET request from XMLHttpRequest
 * @param {string} url
 * @param {"" | "arraybuffer" | "blob" | "document" | "json" | "text"} responseType
 * @param {object} headers
 * @param {number} timeout
 * @returns {Promise<*>} Resolves with response or rejects with error
 * @private
 */
function makeGetRequest(url: string, responseType: XMLHttpRequestResponseType, headers: Headers, timeout: number): Promise<XMLHttpResponse | undefined> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open('GET', url, true);
    xhr.withCredentials = false;
    xhr.responseType = responseType;
    xhr.timeout = timeout;

    Object.keys(headers).forEach((header) => {
      xhr.setRequestHeader(header, headers[header]);
    });

    xhr.onloadend = (_): void => {
      if (xhr.status === 200) {
        resolve(xhr.response as XMLHttpResponse);
      } else {
        reject(`Bad response for GET request: ${xhr.status} ${url}`);
      }
    };
    xhr.ontimeout = (_) => {
      return reject('request timed out');
    };
    xhr.onerror = (ev): void => {
      reject(`request error: ${xhr.status}\n${ev}\n${xhr.response}`);
    };
    xhr.send();
  });
}
