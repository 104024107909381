import {createSlice} from '@reduxjs/toolkit';
import {fontSelectorExtraReducers} from './font-selector-extra-reducers.js';
import {fontSelectorReducers} from './font-selector-reducers.js';
import {FONT_ITEM_SELECTION_STATES} from '@Libraries/font-library.ts';

const initialState = () => {
  return {
    pmwFontsVersion: null,
    pmwFonts: null,
    userFonts: null,
    fancyTextFonts: null,
    languageScripts: null,
    areFontsLoading: true,
    selectedFont: {
      idfont: null,
      fontFamily: '',
      isInternalFont: true,
      // for when we show "fetching font" and are loading font on canvas in editor
      fontItemSelectedState: FONT_ITEM_SELECTION_STATES.NOT_SELECTED,
    },
  };
};

const fontSelectorSlice = createSlice({
  name: 'fontSelectorReact',
  initialState,
  reducers: {
    ...fontSelectorReducers,
  },
  extraReducers: {
    ...fontSelectorExtraReducers,
  },
});

export const {
  selectFontWithoutLoading,
  setPublicFonts,
  setUserFonts,
  setLanguageScripts,
  setPmwFonts,
  removeUserFont,
  addUserFont,
  addUploadedFontToUserFonts,
  setDefaultFontAsSelectedFont,
  setFontsLoading,
  setPMWFontsVersion,
} = fontSelectorSlice.actions;
export const fontSelectorReducer = fontSelectorSlice.reducer;
