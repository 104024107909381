import type { PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {AddItemsToGridProps, GridStorageTypes} from '@Components/base-grid';
import type {GridPMWStockMediaItemStorage} from '@Components/base-grid/components/grid-pmwstockmedia-item/grid-pmw-stock-media-item';
import {arraysEqual, insert} from '@Utils/array.util';

interface GridsStorage {
  [key: string]: GridStorageTypes;
}
interface ManagePMWSavedStateProps {
  savedGridsHashmap: GridsStorage;
  gridSelectionGroupHashmap: SelectionGroupHashmap;
}

interface GridSelectionGroupStorage {
  savedGridsHashmap: Record<string, null>;
  maxSelectionNumber: number;
  selectedItemIdHashmap: Record<string, number>;
}

interface SelectionGroupHashmap {
  [key: string]: GridSelectionGroupStorage;
}

interface ManagePmwStockPanelState {
  PMWStockChanges: Record<string, Partial<GridPMWStockMediaItemStorage>>;
  savedGridsHashmap: GridsStorage;
  gridSelectionGroupHashmap: SelectionGroupHashmap;
}

const initialState: ManagePmwStockPanelState = {
  PMWStockChanges: {},
  savedGridsHashmap: {},
  gridSelectionGroupHashmap: {},
};

interface AddChangeToLogItemProps {
  changeToLog: Partial<GridPMWStockMediaItemStorage>;
  itemToChange: Partial<GridPMWStockMediaItemStorage>;
}

interface AddLazyGridActionProps {
  id: string;
  gridSelectionGroupId?: string;
  maxSelectionNumber?: number;
}

const doesGridExistInStore = (state: ManagePMWSavedStateProps, gridId: string): boolean => {
  return state.savedGridsHashmap[gridId] !== undefined;
};

const addGridToSelecctionGroup = (gridId: string, gridSelectionGroupId: string | undefined, maxSelectionNumber: number | undefined, state: ManagePMWSavedStateProps): void => {
  const selectionGroupId = gridSelectionGroupId ?? gridId;

  if (!state.gridSelectionGroupHashmap[selectionGroupId]) {
    state.gridSelectionGroupHashmap[selectionGroupId] = {
      savedGridsHashmap: {},
      maxSelectionNumber: maxSelectionNumber ?? 0,
      selectedItemIdHashmap: {},
    };
  }
  state.gridSelectionGroupHashmap[selectionGroupId].savedGridsHashmap[gridId] = null;
};

export const managePmwStockPanelSlice = createSlice({
  name: 'ManagePMWStockPanel',
  initialState,
  reducers: {
    addLazyGridManagePMW: (state, action: PayloadAction<AddLazyGridActionProps>) => {
      if (!doesGridExistInStore(state, action.payload.id)) {
        state.savedGridsHashmap[action.payload.id] = {
          items: [],
          requestNum: 1,
        };

        addGridToSelecctionGroup(action.payload.id, action.payload?.gridSelectionGroupId, action.payload?.maxSelectionNumber, state);
      }
    },
    addItemsToGridManagePMW: (state, action: PayloadAction<AddItemsToGridProps>) => {
      const existingGridItems = state.savedGridsHashmap[action.payload.id].items;
      state.savedGridsHashmap[action.payload.id].items = insert(existingGridItems, action.payload.index ?? existingGridItems.length, action.payload.items);
    },
    addChangeToLog: (state, action: PayloadAction<AddChangeToLogItemProps>) => {
      const {changeToLog, itemToChange} = action.payload;
      if (changeToLog.id) {
        if (!state.PMWStockChanges[changeToLog.id]) {
          state.PMWStockChanges[changeToLog.id] = {};
        }

        if (!state.PMWStockChanges[changeToLog.id].changes) {
          state.PMWStockChanges[changeToLog.id].changes = {};
        }

        if (!state.PMWStockChanges[changeToLog.id].original) {
          state.PMWStockChanges[changeToLog.id].original = itemToChange;
        }

        if (state.PMWStockChanges[changeToLog.id].original) {
          const originalItem = state.PMWStockChanges[changeToLog.id].original as Partial<GridPMWStockMediaItemStorage>;
          const changeToLogKeys = Object.keys(changeToLog);

          let isChangedItemEqualToOriginal = changeToLogKeys.every((key) => {
            if (key !== 'keywords') {
              return originalItem[key] !== null && originalItem[key] !== undefined && originalItem[key] === changeToLog[key];
            }
            return true;
          });

          if (changeToLog.keywords) {
            isChangedItemEqualToOriginal = isChangedItemEqualToOriginal && arraysEqual(changeToLog.keywords, originalItem.keywords ?? []);
          }

          if (isChangedItemEqualToOriginal) {
            delete state.PMWStockChanges[changeToLog.id];
          } else {
            state.PMWStockChanges[changeToLog.id].changes = {...state.PMWStockChanges[changeToLog.id].changes, ...changeToLog} as Partial<GridPMWStockMediaItemStorage>;
          }
        }
      }
    },
    clearGridItemsManagePMW: (state) => {
      state.PMWStockChanges = {};
      state.gridSelectionGroupHashmap = {};
      state.savedGridsHashmap = {};
    },
  },
});

export const {addLazyGridManagePMW, addChangeToLog, clearGridItemsManagePMW} = managePmwStockPanelSlice.actions;
export const managePmwStockReducer = managePmwStockPanelSlice.reducer;
