import {isMobile} from 'react-device-detect';
import {isiOS17point4OrAbove} from '@Utils/device-detect.util';
import {isWebmSupported} from '@Utils/browser.util';
import {roundPrecision} from '@Utils/math.util';

interface LoadVideoOptsInterface {
  videoElementAttributes?: Record<any, string>;

  onProgress?(val: number): void;
}

export interface RemoveVideoBackgroundTrimData {
  trimStart: number;
  trimEnd: number;
}

interface RemoveVideoBackgroundResponse {
  remainingRemoveBackgroundCredits: number;
  status: string;
  error: string;
  trimData: RemoveVideoBackgroundTrimData;
}

/**
 * Message string for when play is interrupted by pause
 * @type {string}
 */
const PLAY_INTERRUPTED_BY_PAUSE_ERROR_MESSAGE = 'The play() request was interrupted by a call to pause()';

export const loadVideo = async (url: string, opts: LoadVideoOptsInterface = {}): Promise<HTMLVideoElement> => {
  const videoElementAttributes = opts.videoElementAttributes ?? {};
  if (canVideosBePartiallyLoaded()) {
    return loadVideoElement(url, videoElementAttributes);
  }

  return loadWholeVideo(url, videoElementAttributes, opts.onProgress);
};

export const doesVideoHaveAudio = (videoElement: HTMLVideoElement): boolean => {
  // @ts-ignore
  return videoElement.mozHasAudio || Boolean(videoElement.webkitAudioDecodedByteCount) || Boolean(videoElement.audioTracks && videoElement.audioTracks.length);
};

export const getEstimatedTimeForBackgroundRemoval = async (videoHashedFilename: string, trimStartTime: number, trimEndTime: number): Promise<number> => {
  return (await window.PMW.readLocal('removevideobackground/getEstimatedTimeForBackgroundRemoval', {
    videoHashedFilename,
    trimStartTime,
    trimEndTime,
  })) as Promise<number>;
};

export const removeUserVideoBackground = async (videoHashedFilename: string, trimStartTime: number, trimEndTime: number): Promise<RemoveVideoBackgroundResponse> => {
  return (await window.PMW.writeLocal('removevideobackground/removeUserVideoBackground', {
    videoHashedFilename,
    trimStartTime,
    trimEndTime,
  })) as Promise<RemoveVideoBackgroundResponse>;
};

export const doesRemovedBackgroundExist = async (videoHashedFilename: string, trimStartTime: number, trimEndTime: number): Promise<boolean> => {
  return (await window.PMW.readLocal('removevideobackground/doesRemovedBackgroundExist', {
    videoHashedFilename,
    trimStartTime,
    trimEndTime,
  })) as Promise<boolean>;
};

export const getCreditsToChargeForVideoBackgroundRemovalForUser = async (videoHashedFilename: string, trimStartTime: number, trimEndTime: number): Promise<number> => {
  return (await window.PMW.readLocal('removevideobackground/getCreditsToChargeForVideoBackgroundRemoval', {
    videoHashedFilename,
    trimStartTime,
    trimEndTime,
  })) as Promise<number>;
};

export const removeVideoBackground = async (
  videoHashedFilename: string,
  trimStartTime: number,
  trimEndTime: number,
  estimatedAjaxTimeoutInMilliseconds = 1800000
): Promise<RemoveVideoBackgroundResponse> => {
  const trimStartRounded = roundPrecision(trimStartTime, 6);
  const trimEndRounded = roundPrecision(trimEndTime, 6);
  return window.PMW.pollingAjaxCallAsync(
    removeUserVideoBackground.bind(null, videoHashedFilename, trimStartRounded, trimEndRounded),
    checkVideoBackgroundRemovalStatus,
    estimatedAjaxTimeoutInMilliseconds
  ) as Promise<RemoveVideoBackgroundResponse>;
};

const checkVideoBackgroundRemovalStatus = async (pollingKey: string): Promise<any> => {
  return window.PMW.readLocal('removevideobackground/getVideoBackgroundRemovalStatus', {
    pollingKey,
  });
};

/**
 * Returns the browser compatible file extension
 * @param {string} fileExtension
 * @return {string}
 */
export const getCompatibleVideoFileExtension = (fileExtension: string): string => {
  const FALLBACK_VIDEO_EXTENSION_FOR_WEBM = 'mp4';
  if (fileExtension === 'webm' && !isWebmSupported()) {
    return FALLBACK_VIDEO_EXTENSION_FOR_WEBM;
  }
  return fileExtension;
};

/**
 * Play function
 * @param video
 * @return {Promise<void>}
 */
export const playVideo = async (video: HTMLVideoElement): Promise<void> => {
  try {
    const promise = video.play();

    if (typeof promise !== 'undefined') {
      await promise;
    }
  } catch (e: any) {
    if (!e.message.includes(PLAY_INTERRUPTED_BY_PAUSE_ERROR_MESSAGE)) {
      throw e;
    }
  }
};

/**
 * Loads the gif to be checked for animation
 * @param file
 */
export const isAnimatedGif = (file: File): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onloadend = onGifLoaded.bind(this, (isAnimated: boolean) => {
      resolve(isAnimated);
    });
    fileReader.onabort = reject;
    fileReader.onerror = reject;
    fileReader.readAsArrayBuffer(file);
  });
};
/**
 * Returns whether the filetype is of a video
 * @param {string} fileType
 * @return {boolean}
 */
export const isFiletypeVideo = (fileType: string): boolean => {
  if (fileType === '') {
    return false;
  }
  return fileType.indexOf('video/') !== -1 || fileType.indexOf('image/gif') !== -1;
};

/**
 * Checks whether the loaded gif is animated
 * @param {function} cb
 * @param {event} e
 */
const onGifLoaded = (cb: Function, e: ProgressEvent<FileReader>) => {
  if (e.target === null || e.target.result === null || typeof e.target.result === 'string') {
    cb(false);
    return;
  }

  const arr = new Uint8Array(e.target.result);
  let i;
  let len;
  const {length} = arr;
  let frames = 0;
  // make sure it's a gif (GIF8)
  if (arr[0] !== 0x47 || arr[1] !== 0x49 || arr[2] !== 0x46 || arr[3] !== 0x38) {
    cb(false);
  }
  // ported from php http://www.php.net/manual/en/function.imagecreatefromgif.php#104473
  // an animated gif contains multiple "frames", with each frame having a
  // header made up of:
  // * a static 4-byte sequence (\x00\x21\xF9\x04)
  // * 4 variable bytes
  // * a static 2-byte sequence (\x00\x2C) (some variants may use \x00\x21 ?)
  // We read through the file til we reach the end of the file, or we've found
  // at least 2 frame headers
  for (i = 0, len = length - 3; i < len && frames < 2; ++i) {
    if (arr[i] === 0x00 && arr[i + 1] === 0x21 && arr[i + 2] === 0xf9) {
      const blocklength = arr[i + 3];
      const afterblock = i + 4 + blocklength;
      if (afterblock + 1 < length && arr[afterblock] === 0x00 && (arr[afterblock + 1] === 0x2c || arr[afterblock + 1] === 0x21)) {
        frames++;
      }
    }
  }
  // if frame count > 1, it's animated
  cb(frames > 1);
};

/**
 * Whether to load video as they stream or load the whole video before playing.
 */
const canVideosBePartiallyLoaded = (): boolean => {
  if (isiOS17point4OrAbove()) {
    return true;
  }
  return !isMobile;
};

/**
 * Loads a new video element with the url provided
 */
export const loadVideoElement = (url: string, videoElementAttributes: Record<any, string> = {}): Promise<HTMLVideoElement> => {
  return new Promise((resolve, reject) => {
    const videoElement = document.createElement('video');
    // playsinline attribute preventd os like iOS from playing video in full screen
    videoElement.setAttribute('playsinline', '');
    videoElement.setAttribute('webkit-playsinline', '');
    videoElement.crossOrigin = 'anonymous';
    for (const [key, value] of Object.entries(videoElementAttributes)) {
      videoElement.setAttribute(key, value);
    }
    // Preload set to auto because of a browser bug that unables to detect audio see https://bugs.chromium.org/p/chromium/issues/detail?id=992888#c12
    videoElement.preload = 'auto';

    $(videoElement).one('canplaythrough', () => {
      resolve(videoElement);
    });
    videoElement.addEventListener('abort', reject, false);
    videoElement.addEventListener('error', reject, false);
    videoElement.src = url;
    videoElement.load();
  });
};

const loadWholeVideo = async (url: string, videoElementAttributes: Record<any, string>, onProgress: (val: number) => void = (): void => {}): Promise<HTMLVideoElement> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.onload = async (): Promise<void> => {
      if (xhr.status === 200) {
        window.URL = window.URL || window.webkitURL;
        const arrayBufferView = new Uint8Array(xhr.response as Iterable<number>);
        const blob = new Blob([arrayBufferView], {type: 'video/mp4'});
        resolve(await loadVideoElement(window.URL.createObjectURL(blob), videoElementAttributes));
      } else {
        reject(new Error('Failed to load video'));
      }
    };

    xhr.onabort = reject.bind(this);
    xhr.onerror = reject.bind(this);
    xhr.responseType = 'arraybuffer';

    if (onProgress) {
      xhr.onprogress = (e): void => {
        if (e.lengthComputable) {
          onProgress(e.loaded / e.total);
        } else {
          onProgress(0.98);
        }
      };
    }
    xhr.send();
  });
};

export const isVideoFile = (fileUrl: string): boolean => {
  // Helper function to extract the file extension from the URL
  function getFileExtension(url: string): string {
    const extensionMatch = url.match(/\.([a-z0-9]+)$/i);
    return extensionMatch ? extensionMatch[1] : '';
  }

  // Extract the file extension from the URL
  const fileExtension = getFileExtension(fileUrl);

  // List of common video file extensions
  const videoExtensions = ['mp4', 'avi', 'mkv', 'mov', 'wmv', 'flv', 'webm', 'ogg', 'mpg', 'mpeg'];

  // Convert the extracted extension to lowercase for case-insensitive comparison
  const lowerCaseExtension = fileExtension.toLowerCase();

  // Check if the extension is in the list of video extensions
  return videoExtensions.includes(lowerCaseExtension);
};
