import type {Poster} from '@PosterWhiteboard/poster/poster.class';
import {CommonMethods} from '@PosterWhiteboard/common-methods';

export interface BleedObject {
  enabled: boolean;
  val: number;
  unit: BleedUnit;
}

export enum BleedUnit {
  INCHES = 'INCHES',
  MILLI_METER = 'MILLI_METER',
}

const DEFAULT_INCH_VALUE = 0.125;
const DEFAULT_MM_VALUE = 3;

export class Bleed extends CommonMethods {
  private poster: Poster;
  public enabled = false;
  public val = DEFAULT_INCH_VALUE;
  public unit: BleedUnit = BleedUnit.INCHES;

  constructor(poster: Poster) {
    super();
    this.poster = poster;
  }

  public toObject(): BleedObject {
    return {
      enabled: this.enabled,
      unit: this.unit,
      val: this.val,
    };
  }

  public updateFromObject(bleedObject: Partial<BleedObject>): void {
    this.copyVals(bleedObject);
    this.poster.redux.updateReduxData();
  }

  public getValInPixel(): number {
    let factor;
    switch (this.unit) {
      case BleedUnit.MILLI_METER:
        factor = 3.779;
        break;
      case BleedUnit.INCHES:
        factor = 96;
        break;
      default:
        throw new Error('Unhanlded bleedunit');
    }

    return this.val * factor;
  }
}

export const getDefaultValForUnit = (unit: BleedUnit): number => {
  switch (unit) {
    case BleedUnit.MILLI_METER:
      return DEFAULT_MM_VALUE;
    case BleedUnit.INCHES:
      return DEFAULT_INCH_VALUE;
    default:
      throw new Error('Unhanlded bleedunit');
  }
};
