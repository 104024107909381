import type {Line} from '@Utils/math.util';
import type {FabricObject, TMat2D} from '@postermywall/fabricjs-2';
import {Point} from '@postermywall/fabricjs-2';

export const snapItemToLines = (item: FabricObject, lines: Array<Line>, snappingThreshold: number, viewportTransform: TMat2D, zoom: number): void => {
  const itemCenter = item.getCenterPoint();
  const itemLeft = itemCenter.x;
  const itemTop = itemCenter.y;
  const itemBoundingRect = item.getBoundingRect();
  const itemHeight = itemBoundingRect.height / viewportTransform[3];
  const itemWidth = itemBoundingRect.width / viewportTransform[0];

  for (const line of lines) {
    const objectTop = line.y1 / zoom;
    const objectLeft = line.x1 / zoom;

    if (isInRange(objectTop, itemTop - itemHeight / 2, snappingThreshold)) {
      item.setPositionByOrigin(new Point(itemLeft, objectTop + itemHeight / 2), 'center', 'center');
    }
    if (isInRange(objectTop, itemTop + itemHeight / 2, snappingThreshold)) {
      item.setPositionByOrigin(new Point(itemLeft, objectTop - itemHeight / 2), 'center', 'center');
    }
    if (isInRange(objectTop, itemTop, snappingThreshold)) {
      item.setPositionByOrigin(new Point(itemLeft, objectTop), 'center', 'center');
    }
    if (isInRange(objectLeft, itemLeft - itemWidth / 2, snappingThreshold)) {
      item.setPositionByOrigin(new Point(objectLeft + itemWidth / 2, itemTop), 'center', 'center');
    }
    if (isInRange(objectLeft, itemLeft + itemWidth / 2, snappingThreshold)) {
      item.setPositionByOrigin(new Point(objectLeft - itemWidth / 2, itemTop), 'center', 'center');
    }
    if (isInRange(objectLeft, itemLeft, snappingThreshold)) {
      item.setPositionByOrigin(new Point(objectLeft, itemTop), 'center', 'center');
    }
  }
};

const isInRange = (value1: number, value2: number, snappingThreshold: number): boolean => {
  const roundedValue1 = Math.round(value1);
  const roundedValue2 = Math.round(value2);
  for (let i = roundedValue1 - snappingThreshold, len = roundedValue1 + snappingThreshold; i <= len; i++) {
    if (i === roundedValue2) {
      return true;
    }
  }
  return false;
};
