import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {FabricPositionalParams} from '@Utils/fabric.util';

interface StateProps {
  openPosterPopUpMenu: boolean;
  popUpPosition: FabricPositionalParams | undefined;
  target: any | undefined;
  eventCanvasOffset: FabricPositionalParams | undefined;
}

const initialState: StateProps = {
  openPosterPopUpMenu: false,
  popUpPosition: undefined,
  target: undefined,
  eventCanvasOffset: undefined,
};

const PosterPopUpMenu = createSlice({
  name: 'PosterPopUpMenu',
  initialState,
  reducers: {
    updatePopUpStateAndPosition: (state: StateProps, action: PayloadAction<StateProps>) => {
      state.openPosterPopUpMenu = action.payload.openPosterPopUpMenu;
      state.popUpPosition = action.payload.popUpPosition;
      state.target = action.payload.target;
      state.eventCanvasOffset = action.payload.eventCanvasOffset;
    },
  },
});

export const {updatePopUpStateAndPosition} = PosterPopUpMenu.actions;
export const PosterPopUpMenuReducer = PosterPopUpMenu.reducer;
