import type {Line} from '@Utils/math.util';
import {snapItemToLines} from '@PosterWhiteboard/page/snap-items';
import type {CanvasEvents, TMat2D} from '@postermywall/fabricjs-2';
import {Canvas} from '@postermywall/fabricjs-2';
import type {Page} from './page.class';

const GRID_STROKE = 'rgba(192,192,192, 0.5)';
const THIN_GRID_LINE_WIDTH = 1;
const THICK_GRID_LINE_WIDTH = 2;
const MAX_SNAPPING_THRESHOLD = 7;

export class RenderGrid {
  public page: Page;
  private canvasCleared = false;
  private zoom = 1;
  private viewportTransform: TMat2D;

  constructor(page: Page) {
    this.page = page;
    this.viewportTransform = this.page.fabricCanvas.viewportTransform;
    if (this.page.fabricCanvas instanceof Canvas) {
      this.initAligningGuidelines();
    }
  }

  private initAligningGuidelines(): void {
    this.page.fabricCanvas.on('mouse:down', this.onMouseDown.bind(this));
    this.page.fabricCanvas.on('object:moving', this.onObjectMoving.bind(this));
    this.page.fabricCanvas.on('before:render', this.beforeRender.bind(this));
    this.page.fabricCanvas.on('after:render', this.afterRender.bind(this));
  }

  private beforeRender(): void {
    if (this.page.fabricCanvas instanceof Canvas && this.page.fabricCanvas.contextTop) {
      this.page.fabricCanvas.contextTopDirty = true;
      this.canvasCleared = true;
    }
  }

  private afterRender(): void {
    this.drawGridLines();
  }

  private onMouseDown(): void {
    this.viewportTransform = this.page.fabricCanvas.viewportTransform;
    this.zoom = this.page.fabricCanvas.getZoom();
  }

  private drawGridLines(): void {
    if (this.page.poster.grid.enabled && this.canvasCleared) {
      const gridLines = this.getGridLines();
      const ctx = this.page.fabricCanvas.contextContainer;

      for (let i = 0; i < gridLines.length; i++) {
        ctx.save();
        if (i % 5 === 0) {
          ctx.lineWidth = THICK_GRID_LINE_WIDTH;
        } else {
          ctx.lineWidth = THIN_GRID_LINE_WIDTH;
        }
        ctx.strokeStyle = GRID_STROKE;
        ctx.beginPath();
        ctx.moveTo(gridLines[i].x1, gridLines[i].y1);
        ctx.lineTo(gridLines[i].x2, gridLines[i].y2);
        ctx.stroke();
        ctx.restore();
      }
      this.canvasCleared = false;
    }
  }

  private getGridLines(): Array<Line> {
    const lines = [];
    const {width, height} = this.page.fabricCanvas;
    const gridLenX = width / this.page.poster.grid.size;
    const gridLenY = height / this.page.poster.grid.size;

    for (let i = 0; i < gridLenY; i++) {
      const distance = i * this.page.poster.grid.size;
      const horizontal = {x1: 0, y1: distance, x2: width, y2: distance};
      lines.push(horizontal);
    }
    for (let i = 0; i < gridLenX; i++) {
      const distance = i * this.page.poster.grid.size;
      const vertical = {x1: distance, y1: 0, x2: distance, y2: height};
      lines.push(vertical);
    }

    return lines;
  }

  private onObjectMoving(e: CanvasEvents['object:moving']): void {
    if (this.page.poster.grid.enabled && this.page.fabricCanvas instanceof Canvas) {
      const activeObject = e.target;
      if (!activeObject) {
        return;
      }
      // TODO: This shouldn't be accessing a private property
      // eslint-disable-next-line no-underscore-dangle
      const transform = this.page.fabricCanvas._currentTransform;

      if (!transform) return;

      snapItemToLines(activeObject, this.getGridLines(), this.getSnappingThreshold(), this.viewportTransform, this.zoom);
    }
  }

  private getSnappingThreshold(): number {
    return Math.min(Math.ceil(this.page.poster.grid.size / 10), MAX_SNAPPING_THRESHOLD);
  }
}
