import type {Line} from '@Utils/math.util';
import {FoldType} from '@PosterWhiteboard/poster/fold.class';
import type {TMat2D} from '@postermywall/fabricjs-2';
import {Canvas} from '@postermywall/fabricjs-2';
import type {Page} from './page.class';

const LINE_STROKE_COLOR = '#3FBCE8';
const LINE_STROKE_COLOR_WIDTH = 1;

export class RenderFold {
  public page: Page;
  private canvasCleared = false;
  private zoom = 1;
  private viewportTransform: TMat2D;

  constructor(page: Page) {
    this.page = page;
    this.viewportTransform = this.page.fabricCanvas.viewportTransform;
    if (this.page.fabricCanvas instanceof Canvas) {
      this.initAligningGuidelines();
    }
  }

  private initAligningGuidelines(): void {
    this.page.fabricCanvas.on('mouse:down', this.onMouseDown.bind(this));
    this.page.fabricCanvas.on('before:render', this.beforeRender.bind(this));
    this.page.fabricCanvas.on('after:render', this.afterRender.bind(this));
  }

  private beforeRender(): void {
    if (this.page.fabricCanvas instanceof Canvas && this.page.fabricCanvas.contextTop) {
      this.page.fabricCanvas.contextTopDirty = true;
      this.canvasCleared = true;
    }
  }

  private afterRender(): void {
    this.drawFolds();
  }

  private onMouseDown(): void {
    this.viewportTransform = this.page.fabricCanvas.viewportTransform;
    this.zoom = this.page.fabricCanvas.getZoom();
  }

  private drawFolds(): void {
    if (this.canvasCleared) {
      const gridLines = this.getFoldLines();
      const ctx = this.page.fabricCanvas.contextContainer;

      for (let i = 0; i < gridLines.length; i++) {
        ctx.save();
        ctx.lineWidth = LINE_STROKE_COLOR_WIDTH;
        ctx.strokeStyle = LINE_STROKE_COLOR;
        ctx.setLineDash([5, 3]);
        ctx.beginPath();
        ctx.moveTo(gridLines[i].x1, gridLines[i].y1);
        ctx.lineTo(gridLines[i].x2, gridLines[i].y2);
        ctx.stroke();
        ctx.restore();
      }
      this.canvasCleared = false;
    }
  }

  private getFoldLines(): Array<Line> {
    if (this.page.poster.fold.isBiFold()) {
      return this.getBiFoldGuideLines();
    }
    if (this.page.poster.fold.isTriFold()) {
      return this.getTriFoldGuideLines();
    }
    if (this.page.poster.fold.isFourPanelFold()) {
      return this.getFourPanelFoldGuideLines();
    }
    return [];
  }

  private getTriFoldGuideLines(): Array<Line> {
    const lines = [];
    const width = this.page.poster.getCanvasTotalWidth();
    const height = this.page.poster.getCanvasTotalHeight();

    if (this.page.poster.fold.type === FoldType.TRI_FOLD_HORIZONTAL) {
      lines.push({
        x1: 0,
        y1: height * (1 / 3) + this.page.fabricCanvas.viewportTransform[5],
        x2: width + this.page.fabricCanvas.viewportTransform[4],
        y2: height * (1 / 3) + this.page.fabricCanvas.viewportTransform[5],
      });
      lines.push({
        x1: 0,
        y1: height * (2 / 3) + this.page.fabricCanvas.viewportTransform[5],
        x2: width + this.page.fabricCanvas.viewportTransform[4],
        y2: height * (2 / 3) + this.page.fabricCanvas.viewportTransform[5],
      });
    } else if (this.page.poster.fold.type === FoldType.TRI_FOLD_VERTICAL) {
      lines.push({
        x1: width * (1 / 3) + this.page.fabricCanvas.viewportTransform[4],
        y1: 0,
        x2: width * (1 / 3) + this.page.fabricCanvas.viewportTransform[4],
        y2: height + this.page.fabricCanvas.viewportTransform[5],
      });
      lines.push({
        x1: width * (2 / 3) + this.page.fabricCanvas.viewportTransform[4],
        y1: 0,
        x2: width * (2 / 3) + this.page.fabricCanvas.viewportTransform[4],
        y2: height + this.page.fabricCanvas.viewportTransform[5],
      });
    }
    return lines;
  }

  private getFourPanelFoldGuideLines(): Array<Line> {
    const lines = [];
    const width = this.page.poster.getCanvasTotalWidth();
    const height = this.page.poster.getCanvasTotalHeight();

    if (this.page.poster.fold.type === FoldType.FOUR_PANEL_HORIZONTAL) {
      lines.push({
        x1: 0,
        y1: height * (1 / 4) + this.page.fabricCanvas.viewportTransform[5],
        x2: width + this.page.fabricCanvas.viewportTransform[4],
        y2: height * (1 / 4) + this.page.fabricCanvas.viewportTransform[5],
      });
      lines.push({
        x1: 0,
        y1: height * (2 / 4) + this.page.fabricCanvas.viewportTransform[5],
        x2: width + this.page.fabricCanvas.viewportTransform[4],
        y2: height * (2 / 4) + this.page.fabricCanvas.viewportTransform[5],
      });
      lines.push({
        x1: 0,
        y1: height * (3 / 4) + this.page.fabricCanvas.viewportTransform[5],
        x2: width + this.page.fabricCanvas.viewportTransform[4],
        y2: height * (3 / 4) + this.page.fabricCanvas.viewportTransform[5],
      });
    } else if (this.page.poster.fold.type === FoldType.FOUR_PANEL_VERTICAL) {
      lines.push({
        x1: width * (1 / 4) + this.page.fabricCanvas.viewportTransform[4],
        y1: 0,
        x2: width * (1 / 4) + this.page.fabricCanvas.viewportTransform[4],
        y2: height + this.page.fabricCanvas.viewportTransform[5],
      });
      lines.push({
        x1: width * (2 / 4) + this.page.fabricCanvas.viewportTransform[4],
        y1: 0,
        x2: width * (2 / 4) + this.page.fabricCanvas.viewportTransform[4],
        y2: height + this.page.fabricCanvas.viewportTransform[5],
      });
      lines.push({
        x1: width * (3 / 4) + this.page.fabricCanvas.viewportTransform[4],
        y1: 0,
        x2: width * (3 / 4) + this.page.fabricCanvas.viewportTransform[4],
        y2: height + this.page.fabricCanvas.viewportTransform[5],
      });
    }
    return lines;
  }

  private getBiFoldGuideLines(): Array<Line> {
    const lines = [];
    const width = this.page.poster.getCanvasTotalWidth();
    const height = this.page.poster.getCanvasTotalHeight();

    if (this.page.poster.fold.type === FoldType.BI_FOLD_HORIZONTAL) {
      lines.push({
        x1: 0,
        y1: height / 2 + this.page.fabricCanvas.viewportTransform[5],
        x2: width + this.page.fabricCanvas.viewportTransform[4],
        y2: height / 2 + this.page.fabricCanvas.viewportTransform[5],
      });
    } else if (this.page.poster.fold.type === FoldType.BI_FOLD_VERTICAL) {
      lines.push({
        x1: width / 2 + this.page.fabricCanvas.viewportTransform[4],
        y1: 0,
        x2: width / 2 + this.page.fabricCanvas.viewportTransform[4],
        y2: height + this.page.fabricCanvas.viewportTransform[5],
      });
    }
    return lines;
  }
}
