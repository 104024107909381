import {HistoryState} from '@PosterWhiteboard/poster/poster-history/history-state';
import type {PosterLoadObject} from '@PosterWhiteboard/poster/poster.types';

export class HistoryStatePoster extends HistoryState {
  public posterLoadObject: PosterLoadObject;

  public constructor(posterLoadObject: PosterLoadObject) {
    super();
    this.posterLoadObject = posterLoadObject;
  }
}
