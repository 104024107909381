import type {ReactElement} from 'react';
import React from 'react';
import {CircularProgressLoader} from '@Components/circular-progress-loader';
import {getFitToScreenScale, modeHasBufferForFitToScreen} from '@PosterWhiteboard/poster/poster-scaling.class';
import type {PosterModeObject} from '@PosterWhiteboard/poster/poster-mode.class';
import styles from './poster-whiteboard-loader.module.scss';

interface PosterWhiteboardLoaderProps {
  isCenterAligned: boolean;
  posterMode: PosterModeObject;
}

export function PosterWhiteboardLoader({...props}: PosterWhiteboardLoaderProps): ReactElement {
  const scale = getFitToScreenScale(props.posterMode.details.posterWidth, props.posterMode.details.posterHeight, modeHasBufferForFitToScreen(props.posterMode.details.type));

  return (
    <div
      className={`${styles.container} flex-center ${props.isCenterAligned ? styles.isCenterAligned : ''}`}
      style={{
        width: props.posterMode.details.posterWidth * scale,
        height: props.posterMode.details.posterHeight * scale,
      }}
    >
      <CircularProgressLoader classes="_unmargin" />
    </div>
  );
}
