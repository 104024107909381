import type {TableLayout} from '@PosterWhiteboard/items/layouts/table-layout';
import type {SlantedSportsLayout} from '@PosterWhiteboard/items/layouts/slanted-sports-layout';
import type {OffsetSportsLayout} from '@PosterWhiteboard/items/layouts/offset-sports-layout';
import type {SportsLayout} from '@PosterWhiteboard/items/layouts/sports-layout';
import type {BandLayout} from '@PosterWhiteboard/items/layouts/band-layout';
import type {MenuLayout1} from '@PosterWhiteboard/items/layouts/menu-layout-1';
import type {MenuLayout2} from '@PosterWhiteboard/items/layouts/menu-layout-2';
import type {MenuLayout3} from '@PosterWhiteboard/items/layouts/menu-layout-3';
import type {MenuLayout4} from '@PosterWhiteboard/items/layouts/menu-layout-4';
import type {MenuLayout5} from '@PosterWhiteboard/items/layouts/menu-layout-5';
import type {MenuLayout6} from '@PosterWhiteboard/items/layouts/menu-layout-6';

export const BOLD_STROKE_WIDTH_FACTOR = 0.04;

export type LayoutType =
  | TableLayout
  | SlantedSportsLayout
  | OffsetSportsLayout
  | SportsLayout
  | BandLayout
  | MenuLayout1
  | MenuLayout2
  | MenuLayout3
  | MenuLayout4
  | MenuLayout5
  | MenuLayout6;

export enum LayoutTypes {
  TABLE_LAYOUT = 'layout-1',
  BAND_LAYOUT_LEFT_ALIGN = 'layout-2',
  BAND_LAYOUT_RIGHT_ALIGN = 'layout-6',
  BAND_LAYOUT_CENTER_ALIGN = 'layout-7',
  SPORTS_LAYOUT = 'layout-3',
  SLANTED_SPORTS_LAYOUT = 'layout-4',
  STRAIGHT_SPORTS_LAYOUT = 'layout-8',
  OFFSET_SPORTS_LAYOUT = 'layout-9',
  CUSTOM_TABLE_LAYOUT = 'custom-table',
  MENU_LAYOUT_1 = 'layout-10',
  MENU_LAYOUT_2 = 'layout-11',
  MENU_LAYOUT_3 = 'layout-12',
  MENU_LAYOUT_4 = 'layout-13',
  MENU_LAYOUT_5 = 'layout-14',
  MENU_LAYOUT_6 = 'layout-15',
}

export enum LayoutBackgroundTypes {
  NONE = 0,
  COLOR = 1,
  ALTERNATE = 2,
}

export const X_SPACING_DEFAULT_VALUE1 = 250;
export const X_SPACING_DEFAULT_VALUE2 = 200;
export const X_SPACING_DEFAULT_VALUE3 = 100;
export const Y_SPACING_DEFAULT_VALUE = 40;
export const DEFAULT_SPACING_BETWEEN_ITEMS = 5;
