import type { Delta} from 'jsondiffpatch';
import {diff, patch} from 'jsondiffpatch';

/**
 * This is used instead of standard compare because that returns true only if they refer to the exact same object
 * @param obj1
 * @param obj2
 * @returns {boolean}
 */
export const areObjectsEqual = <T>(obj1: T, obj2: T): boolean => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const getSortedKeysOnValue = (obj: {[key: string]: number}): string[] => {
  return Object.entries(obj)
    .sort(([, a], [, b]) => {
      return a - b;
    })
    .reduce((r: string[], cV) => {
      r.push(cV[0]);
      return r;
    }, []);
};

export const getObjectDiff = <T>(object1: T, object2: T): Delta | undefined => {
  return diff(object1, object2);
};

export const patchObjectDiff = <T>(object: T, delta: Delta): any => {
  return patch(object, delta);
};

/**
 * This function checks if the object is empty or not
 * @param object
 * @returns {boolean}
 */
export const isObjectEmpty = <T>(object: T | null | undefined): boolean => {
  if (!object) {
    return true;
  }
  return Object.entries(object).length === 0;
};
