import {browserVersion, isChrome, isEdge} from 'react-device-detect';

export const setCookie = (name: string, value: string, days: number, isStrict?: boolean): void => {
  const date = new Date();
  let cookieStr;
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `; expires=${date.toString()}`;
  cookieStr = `${name}=${value}${expires}; path=/;domain=${window.PMW.DOMAIN}`;

  if (isChrome && parseInt(browserVersion, 10) < 79) {
    cookieStr += ';Secure;';
  } else if (isStrict) {
    cookieStr += ' ;SameSite=Strict;Secure;';
  } else {
    if ((isChrome || isEdge) && parseInt(browserVersion, 10) < 104) {
      cookieStr += ' ;SameSite=None;Secure;';
    } else {
      cookieStr += ' ;SameSite=None;Secure;Partitioned;';
    }
  }

  document.cookie = cookieStr;
};

export const readCookie = (name: string): string | null => {
  const nameEQ = `${name}=`;
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === ' ') cookie = cookie.substring(1, cookie.length);
    if (cookie.indexOf(nameEQ) === 0) return cookie.substring(nameEQ.length, cookie.length);
  }
  return null;
};

export const deleteCookie = (name: string): void => {
  let additionalAttributes = ';SameSite=None;Secure;Partitioned;';
  if ((isChrome || isEdge) && parseInt(browserVersion, 10) < 104) {
    additionalAttributes = '';
  }
  document.cookie = `${encodeURIComponent(name)}=deleted; expires=${new Date(0).toUTCString()};path=/ ;domain=${window.PMW.DOMAIN}${additionalAttributes}`;
};

export const areCookiesEnabled = (): boolean => {
  // todo umar: temp
  const areCookiesEnabledCookieName: string = 'pmw_ace';
  setCookie(areCookiesEnabledCookieName, '1', 1, false);

  if (!readCookie(areCookiesEnabledCookieName)) {
    return false;
  }
  deleteCookie(areCookiesEnabledCookieName);
  return true;
};
