import type {ReactElement} from 'react';
import React, {useEffect} from 'react';
import {Icon} from '@Components/icon-v2';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {Text, TextSize} from '@Components/text';
import type {UploadFileDataTypes, UserMediaType, UserMediaVOResponse} from '@Libraries/user-media-library';
import {getMediaStorageForbackendData, attachMediaDrop, userMediaVOToElementData} from '@Libraries/user-media-library';
import {isArray} from 'lodash';
import {onAddItems} from '@Components/poster-editor/library/poster-editor-open-modals';
import {isTempImageData} from '@Libraries/add-media-library';
import {noop} from '@Utils/general.util';
import {isUserLoggedIn} from '@Libraries/user.library';
import {updateUnlinkedMediaState} from '@Panels/user-media-panel/user-media-panel-reducer';
import styles from './drag-drop-overlay.module.scss';
import {useAppDispatch} from '@/hooks';

function DragDropOverlay(): ReactElement {
  const dispatch = useAppDispatch();
  const onMediaUploaded = (type: UserMediaType, data: UploadFileDataTypes): void => {
    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return;
    }
    if (!isArray(data)) {
      if (isTempImageData(data)) {
        onAddItems([data]);
      } else {
        if (!isUserLoggedIn()) {
          const mediaItem = getMediaStorageForbackendData(data, type);
          dispatch(updateUnlinkedMediaState([mediaItem]));
        }

        const itemData = userMediaVOToElementData(type, data);
        onAddItems([itemData]);
      }
    }
  };

  const processAfterUpload = (type: UserMediaType, data: Array<UserMediaVOResponse> | UserMediaVOResponse): void => {
    if (!isUserLoggedIn() && !isArray(data)) {
      const mediaItem = getMediaStorageForbackendData(data, type);
      dispatch(updateUnlinkedMediaState([mediaItem]));
    }
  };

  useEffect(() => {
    attachMediaDrop('.whiteboard-container', onMediaUploaded, processAfterUpload, noop);
  }, []);

  return (
    <div className={`dragdropoverlay _full-width _full-height ${styles.container}`}>
      <div className={`_full-width _full-height flex-center flex-v-row spacing-p-13 ${styles.content}`}>
        <Icon icon="icon-upload-1" size={IconSize.SIZE_ICON_32} type={IconType.FLAT} shape={IconShape.CIRCLE} />
        <Text className="spacing-m-t-3" val={window.i18next.t('pmwjs_drag_drop_message')} size={TextSize.SMALL} />
      </div>
    </div>
  );
}

export default DragDropOverlay;
