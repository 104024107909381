import type {ReactElement} from 'react';
import React from 'react';
import {updateSpellCheckSettingsState} from '@Components/poster-editor/poster-editor-reducer';
import {Icon} from '@Components/icon-v2';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {openPosterEditorSpellcheckModal} from '@Modals/poster-editor-item-spellcheck-modal';
import {MODALS_IDS} from '@Components/modal-container';
import {Canvas, Textbox} from '@postermywall/fabricjs-2';
import {useAppSelector} from '@/hooks';

export function SpellCheckSettingsButton(): ReactElement {
  const isSpellCheckSettingsActive = useAppSelector((state) => {
    return state.posterEditor.isSpellCheckSettingsActive;
  });
  const spellCheckModal = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.POSTER_EDITOR_ITEM_SPELLCHECK_MODAL];
  });

  const isMobileVariant = useAppSelector((state) => {
    return state.posterEditor.isMobileVariant;
  });

  const handleModalOpenClose = (): void => {
    if (!spellCheckModal?.isOpen) {
      openPosterEditorSpellcheckModal();
    }
  };

  const onSettingsButtonClicked = (): void => {
    if (isMobileVariant) {
      handleModalOpenClose();
    }
    window.PMW.redux.store.dispatch(updateSpellCheckSettingsState(!isSpellCheckSettingsActive));
    applyFocusOnTextarea();
  };

  const applyFocusOnTextarea = (): void => {
    const poster = window.posterEditor?.whiteboard;
    const activeCanvas = poster?.getCurrentPage().fabricCanvas;
    const activeItem = poster?.getSelectedItems()[0];

    if (!(activeCanvas instanceof Canvas)) {
      return;
    }
    const activeObject = activeCanvas?.getActiveObject();
    if (
      (activeItem?.isText() || activeItem?.isSlideshow()) &&
      activeItem.clone &&
      activeItem.clone.hiddenTextarea &&
      activeCanvas &&
      activeObject &&
      activeObject instanceof Textbox
    ) {
      activeItem.clone.hiddenTextarea.focus();
    }
  };

  return (
    <Icon
      isSelected={isSpellCheckSettingsActive}
      onClick={onSettingsButtonClicked}
      icon="icon-settings"
      shape={IconShape.SQUARE}
      size={IconSize.SIZE_ICON_20}
      type={IconType.GHOST}
    />
  );
}
