import {Observable} from '@PosterWhiteboard/observable';
import type {Page} from '@PosterWhiteboard/page/page.class';
import type * as Fabric from '@postermywall/fabricjs-2';
import {GlobalPosterEditorJqueryElement} from '@Components/poster-editor/poster-editor.types';
import {MARGIN_BOTTOM_DEFAULT, scrollPosterVerticalWithDelta} from '@Components/poster-whiteboard/components/poster-vertical-scroll';
import {getPageOffsetOfPointOnFabricCanvasWithViewportTransform} from '@Utils/fabric.util';
import {isIOS, isMobile} from 'react-device-detect';
import {isTextElement} from '@Utils/dom.util';
import {updateKeyboardShift} from '@Components/modal-container/modal-container-reducer';
import {Textbox} from '@postermywall/fabricjs-2';

const ZOOM_FACTOR_ON_SCROLL = 0.001;
const KEYBOARD_MARGIN = 15;

const OFFSCREEN_INPUT_ID = '__fake_input';
const OFFSCREEN_TEXTAREA_ID = '__fake_textarea';

let isResizing: NodeJS.Timeout;
let maxKeyboardHeightInFocusSession = 0;

export class CanvasPanOnTextEdit extends Observable {
  public page: Page;
  public viewPortHeightBeforeKeyboardOpen = 0;
  public viewPortHeightAfterKeyboardOpen = 0;
  public checkForEnterEdit = false;

  public constructor(page: Page) {
    super();
    this.page = page;
    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', this.viewportResizeHandler.bind(this));
      window.visualViewport.addEventListener('resize', () => {
        clearTimeout(isResizing);
        isResizing = setTimeout(this.checkForKeyboardShiftReduced.bind(this), 350);
      });
    }
  }

  private checkForKeyboardShiftReduced(): void {
    if (!isMobile || isIOS || !window.visualViewport) {
      return;
    }
    maxKeyboardHeightInFocusSession = 0;
    if (CanvasPanOnTextEdit.isActiveElementCustomInput()) {
      return;
    }
    const viewportHeight = window.visualViewport.height;
    const {activeElement} = document;
    if (activeElement) {
      const isElementInSmallScreenBottomBar = !!activeElement.closest('.ReactModalPortal__SmallScreen');
      if (isElementInSmallScreenBottomBar) {
        const keyboardMargin = 5;
        const elementOffsetBottom = activeElement.getBoundingClientRect().bottom;
        const isKeyboardCoveringInputElement = elementOffsetBottom + keyboardMargin > viewportHeight;
        const keyboardHeight = Math.max(0, window.innerHeight - viewportHeight);
        if (!isKeyboardCoveringInputElement) {
          const modalTranslateY = window.PMW.redux.store.getState().modals.keyboardShift;
          const hasKeyboardShiftReduced = keyboardHeight < modalTranslateY;
          if (hasKeyboardShiftReduced) {
            window.PMW.redux.store.dispatch(updateKeyboardShift(keyboardHeight));
          }
        }
      }
    }
  }

  private viewportResizeHandler(): void {
    if (!isMobile || !window.visualViewport) {
      return;
    }
    const viewportHeight = window.visualViewport.height;
    if (!isIOS && Math.abs(window.innerHeight - viewportHeight) < 0.5) {
      // reset modal's keyboard shift if viewportHeight is approximately the same as window's innerHeight (when keyboard is closed)
      window.PMW.redux.store.dispatch(updateKeyboardShift(0));
    }

    if (CanvasPanOnTextEdit.isActiveElementCustomInput()) {
      return;
    }

    const {activeElement} = document;
    let isElementInSmallScreenBottomBar = false;

    if (!isIOS && activeElement) {
      const isElementTextOrInput = isTextElement(activeElement);
      if (isElementTextOrInput) {
        const fakeInputElement = CanvasPanOnTextEdit.ensureOffScreenDuplicateInput(activeElement);
        isElementInSmallScreenBottomBar = !!activeElement.closest('.ReactModalPortal__SmallScreen');

        if (isElementInSmallScreenBottomBar) {
          const inputElement = activeElement as HTMLInputElement | HTMLTextAreaElement;
          const elementOffsetBottom = activeElement.getBoundingClientRect().bottom;
          const keyboardMargin = 5;
          const isKeyboardCoveringInputElement = elementOffsetBottom + keyboardMargin > viewportHeight;
          const keyboardHeight = Math.max(0, window.innerHeight - viewportHeight);

          if (isKeyboardCoveringInputElement) {
            if (keyboardHeight > maxKeyboardHeightInFocusSession) {
              fakeInputElement.focus();
              maxKeyboardHeightInFocusSession = keyboardHeight;
              window.PMW.redux.store.dispatch(updateKeyboardShift(keyboardHeight));
              setTimeout(() => {
                inputElement.focus();
              }, 100);
            } else {
              inputElement.focus();
            }
          }
        }
      }
    }

    if (!isElementInSmallScreenBottomBar && this.checkForEnterEdit) {
      this.viewPortHeightAfterKeyboardOpen = viewportHeight;
      if (this.isScrollNeeded()) {
        this.zoomPosterIfFitToScreen();
      }
      setTimeout(() => {
        this.scrollCanvasToText();
      }, 150);
      this.checkForEnterEdit = false;
    }
  }

  public beforeTextEditEntered(): void {
    if (!isMobile || !window.visualViewport) {
      return;
    }
    this.viewPortHeightBeforeKeyboardOpen = window.visualViewport.height;
    this.checkForEnterEdit = true;
  }

  public afterTextEditExited(): void {
    if (!isMobile) {
      return;
    }
    const verticalScrollContainerHtmlElement = window.posterEditor?.elements[GlobalPosterEditorJqueryElement.POSTER_VERTICAL_SCROLL]?.get(0);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const verticalScrollHtmlElement = verticalScrollContainerHtmlElement.children[0];

    if (!verticalScrollContainerHtmlElement || !verticalScrollHtmlElement) {
      return;
    }

    const extraScrollTopValue = verticalScrollContainerHtmlElement.scrollTop - (-this.page.fabricCanvas.viewportTransform[5] + MARGIN_BOTTOM_DEFAULT);
    if (extraScrollTopValue > 0) {
      scrollPosterVerticalWithDelta(-extraScrollTopValue, true);
    }
  }

  public scrollCanvasToText(): void {
    if (!isMobile) {
      return;
    }
    const activeObject = this.page.activeSelection.getActiveObject();

    if (!activeObject || !(activeObject instanceof Textbox && activeObject.isEditing)) {
      return;
    }

    const objectBoundingRect = activeObject.getBoundingRect();
    const {visualViewport} = window;
    if (!visualViewport) {
      return;
    }

    const keyboardHeight = this.viewPortHeightBeforeKeyboardOpen - this.viewPortHeightAfterKeyboardOpen;
    if (keyboardHeight <= 0) {
      return;
    }

    const transformedCloneBoundingRectPosition = getPageOffsetOfPointOnFabricCanvasWithViewportTransform(this.page.fabricCanvas as Fabric.Canvas, {
      left: objectBoundingRect.left,
      top: objectBoundingRect.top,
    });
    const scaledCloneBoundingHeight = objectBoundingRect.height * this.page.fabricCanvas.getZoom();
    const cloneBottomMostVisiblePointTop = transformedCloneBoundingRectPosition.top + scaledCloneBoundingHeight;
    const isKeyboardCoveringText = cloneBottomMostVisiblePointTop + KEYBOARD_MARGIN > visualViewport.height;

    if (isKeyboardCoveringText) {
      const verticalScrollContainerHtmlElement = window.posterEditor?.elements[GlobalPosterEditorJqueryElement.POSTER_VERTICAL_SCROLL]?.get(0);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const verticalScrollHtmlElement = verticalScrollContainerHtmlElement.children[0];

      if (!verticalScrollContainerHtmlElement || !verticalScrollHtmlElement) {
        return;
      }

      const toScrollValue = cloneBottomMostVisiblePointTop + KEYBOARD_MARGIN - visualViewport.height;
      if (toScrollValue > 0.5) {
        scrollPosterVerticalWithDelta(toScrollValue, true);
      } else if (toScrollValue < 0) {
        scrollPosterVerticalWithDelta(toScrollValue, true);
      }
    }
  }

  private getBottomMostVisiblePointTopOfActiveText(): number | undefined {
    const activeObject = this.page.activeSelection.getActiveObject();

    if (!activeObject || !(activeObject instanceof Textbox && activeObject.isEditing)) {
      return undefined;
    }

    const objectBoundingRect = activeObject.getBoundingRect();

    const transformedCloneBoundingRectPosition = getPageOffsetOfPointOnFabricCanvasWithViewportTransform(this.page.fabricCanvas as Fabric.Canvas, {
      left: objectBoundingRect.left,
      top: objectBoundingRect.top,
    });

    const scaledCloneBoundingHeight = objectBoundingRect.height * this.page.fabricCanvas.getZoom();
    return transformedCloneBoundingRectPosition.top + scaledCloneBoundingHeight;
  }

  private isScrollNeeded(): boolean {
    const {visualViewport} = window;
    if (!visualViewport) {
      return false;
    }
    const cloneBottomMostVisiblePointTop = this.getBottomMostVisiblePointTopOfActiveText();
    if (typeof cloneBottomMostVisiblePointTop === 'undefined') {
      return false;
    }
    return cloneBottomMostVisiblePointTop - visualViewport.height > 0;
  }

  private zoomPosterIfFitToScreen(): void {
    if (this.page.poster.scaling.scale <= this.page.poster.scaling.getFitToScreenScale()) {
      this.page.poster.scaling.setZoom(this.page.poster.scaling.scale + ZOOM_FACTOR_ON_SCROLL);
    }
  }

  public static ensureOffScreenDuplicateInput(elementToDuplicate: Element): HTMLInputElement | HTMLTextAreaElement {
    const createDefaultInputElement = (): HTMLInputElement => {
      const offscreenInputElement = document.createElement('input');
      offscreenInputElement.style.position = 'fixed';
      offscreenInputElement.style.top = '0px';
      offscreenInputElement.style.opacity = '0.1';
      offscreenInputElement.style.width = '10px';
      offscreenInputElement.style.height = '10px';
      offscreenInputElement.style.transform = 'translateX(-1000px)';
      offscreenInputElement.id = OFFSCREEN_INPUT_ID;
      offscreenInputElement.type = 'number';

      return offscreenInputElement;
    };

    if (elementToDuplicate.tagName === 'INPUT') {
      const originalInputElement = elementToDuplicate as HTMLInputElement;
      let offscreenInput = document.getElementById(OFFSCREEN_INPUT_ID) as HTMLInputElement | null;

      if (offscreenInput) {
        if (originalInputElement.type !== offscreenInput.type && originalInputElement.type) {
          offscreenInput.type = originalInputElement.type;
        }
      } else {
        offscreenInput = createDefaultInputElement();
        offscreenInput.type = originalInputElement.type;
        document.body.appendChild(offscreenInput);
      }
      return offscreenInput;
    }

    if (elementToDuplicate.tagName === 'TEXTAREA') {
      let offscreenTextarea = document.getElementById(OFFSCREEN_TEXTAREA_ID) as HTMLTextAreaElement | null;

      if (!offscreenTextarea) {
        offscreenTextarea = document.createElement('textarea');
        offscreenTextarea.style.position = 'fixed';
        offscreenTextarea.style.top = '0px';
        offscreenTextarea.style.opacity = '0.1';
        offscreenTextarea.style.width = '10px';
        offscreenTextarea.style.height = '10px';
        offscreenTextarea.style.transform = 'translateX(-1000px)';
        offscreenTextarea.id = OFFSCREEN_TEXTAREA_ID;
        document.body.appendChild(offscreenTextarea);
      }

      return offscreenTextarea;
    }

    const defaultInputElement = createDefaultInputElement();
    document.body.appendChild(defaultInputElement);
    return defaultInputElement;
  }

  public static isActiveElementCustomInput(): boolean {
    const {activeElement} = document;
    if (activeElement) {
      if (activeElement.id === OFFSCREEN_INPUT_ID || activeElement.id === OFFSCREEN_TEXTAREA_ID) {
        return true;
      }
    }
    return false;
  }
}
