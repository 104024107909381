import type {Page} from '@PosterWhiteboard/page/page.class';
import type {ItemType} from '@PosterWhiteboard/items/item/item.types';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import {getMaxDurationItem} from '@PosterWhiteboard/items/item/item.library';
import {POSTER_MAX_DURATION} from '@PosterWhiteboard/poster/poster.types';

export const MIN_ANIMATED_PAGE_DURATION = 5;
export const MIN_PAGE_DURATION = 2;

export const calculatePageDuration = (page: Page): number => {
  const item = getDurationItem(page);
  const itemDuration = item ? item.getPlayDuration() : 0;
  const audioDuration = page.poster.audioClips.getMaxOnPosterEndTime();
  const pageDuration = page.getDuration();

  if (!page.poster.isVideo()) {
    return 0;
  }

  return Math.min(Math.max(itemDuration, getMinDuration(page), audioDuration, pageDuration), POSTER_MAX_DURATION);
};

const getMinDuration = (page: Page): number => {
  if (!page.isVideo()) {
    return 0;
  }
  return page.introAnimation.hasIntroAnimation() ? MIN_ANIMATED_PAGE_DURATION : MIN_PAGE_DURATION;
};

const getDurationItem = (page: Page): ItemType | undefined => {
  const renderItems = [];
  const streamingItems = page.getStreamingItems();

  for (const item of streamingItems) {
    switch (item.gitype) {
      case ITEM_TYPE.VIDEO:
      case ITEM_TYPE.STICKER:
      case ITEM_TYPE.SLIDESHOW:
      case ITEM_TYPE.TRANSCRIPT:
        renderItems.push(item);
        break;

      default:
        break;
    }
  }

  return getMaxDurationItem(renderItems);
};
