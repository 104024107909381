import type {RGBA} from '@Utils/color.util';
import {CommonMethods} from '@PosterWhiteboard/common-methods';

const DEFAULT_STROKE_WEIGHT = 10;
/**
 * The default color
 * @var {Array} RGBA representation of color
 */
export const DEFAULT_DRAW_COLOR: RGBA = [0, 0, 0, 1];

export const PENCIL_BRUSH = 'pencil';
export const CIRCLE_BRUSH = 'circle';
export const SPRAY_BRUSH = 'spray';
export const ERASER_BRUSH = 'eraser';

export interface DrawObject {
  strokeColor: RGBA;
  strokeWeight: number;
  brush: string;
}

export class Draw extends CommonMethods {
  public strokeColor: RGBA = [...DEFAULT_DRAW_COLOR];
  public strokeWeight = DEFAULT_STROKE_WEIGHT;
  // public shadow = new Shadow();
  public brush = PENCIL_BRUSH;

  public toObject(): DrawObject {
    return {
      strokeColor: this.strokeColor,
      strokeWeight: this.strokeWeight,
      brush: this.brush,
    };
  }
}
