import {getSingleActiveItemObject} from '@Components/poster-editor/poster-editor-reducer';
import type {ItemType} from '@PosterWhiteboard/items/item/item.types';
import {useAppSelector} from '@/hooks';

export function useSingleActivePosterItem(): ItemType | null {
  const singleActiveItem = useAppSelector(getSingleActiveItemObject);
  const activeItem = singleActiveItem ? window.posterEditor?.whiteboard?.getItemForId(singleActiveItem.uid) : null;
  if (!activeItem) {
    return null;
  }
  return activeItem;
}
