import {CommonMethods} from '@PosterWhiteboard/common-methods';
import {isEditorMobileVariant} from '@Components/poster-editor/library/poster-editor-library';
import type {Poster} from '@PosterWhiteboard/poster/poster.class';

export interface AlignmentGuidesObject {
  enabled: boolean;
}

export class AlignmentGuides extends CommonMethods {
  public enabled: boolean;
  public poster: Poster;

  constructor(poster: Poster) {
    super();
    this.poster = poster;
    this.enabled = this.getDefaultAlignmentGuidelinesVal();
    this.invalidate();
  }

  public toObject(): AlignmentGuidesObject {
    return {
      enabled: this.enabled,
    };
  }

  public updateFromObject(alignmentGuidesObject: Partial<AlignmentGuidesObject>): void {
    this.copyVals(alignmentGuidesObject);
    this.invalidate();
  }

  private invalidate(): void {
    for (const [, page] of Object.entries(this.poster.pages.pagesHashMap)) {
      if (this.enabled) {
        page.renderAlignmentGuidelines.enableAligningGuidelines();
      } else {
        page.renderAlignmentGuidelines.disableAligningGuidelines();
      }
    }
  }

  private getDefaultAlignmentGuidelinesVal(): boolean {
    return !isEditorMobileVariant();
  }
}
