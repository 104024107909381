import type {ReactElement, SyntheticEvent} from 'react';
import type {IconSize} from '@Components/icon-v2/icon.types';
import type {TextSize} from '@Components/text';

export enum TabSeparatorIcon {
  NONE = 'none',
  CARET_RIGHT = 'icon-caret-right',
}

export enum TabContentWidth {
  AUTO = 'auto',
  STRETCHED = 'stretched',
}

export enum TabContentLayout {
  BLOCK = 'block',
  FLEX = 'flex',
}

export type TabKey = string;
export type TabItems = TabItemContentStorage[];

export type TabClickHandler = (tabKey: TabKey, e: SyntheticEvent) => void;

export interface TabTitle {
  text: string;
  preTitleContent?: ReactElement;
  postTitleContent?: ReactElement;
}

export interface TabItemContentStorage extends TabTitle {
  tabKey: TabKey;
  href?: string;
  containerClassName?: string;
  textClassName?: string;
  className?: string;
  showPremiumCrown?: boolean;
  disabled?: boolean;
  disableSelection?: boolean;
  extraElementAfterTab?: ReactElement;
  extraElementContainerClassName?: string;
}

export interface TabItemContentHandlers {
  href?: string;
  onClick?: (e: SyntheticEvent) => void;
}

export interface TabsMoreOptionsConfig {
  iconSize: IconSize;
  textSize?: TextSize;
  textClasses?: string;
  className?: string;
  containerClassName?: string;
}

export enum TabsThemes {
  DEFAULT = 'default',
  DARK = 'dark',
  PRIMARY_LIGHT = 'primary-light',
  GRADIENT = 'gradient',
}

export enum TabSliderType {
  SLIDER_LINE = 'line',
  SLIDER_BLOCK = 'block',
}
