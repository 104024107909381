import {updateIsUserOnline} from '@/reducers/user-online-status.reducer';

let isPollingForUserOnlineStatus = false;
let userOnlineStatusPollingTimeout: NodeJS.Timeout | undefined;

export const stopIsUserOnlinePoll = (): void => {
  clearInterval(userOnlineStatusPollingTimeout);
};

export const pollIsUserOnlineTillOnline = (): void => {
  if (!isPollingForUserOnlineStatus) {
    pollUserOnlineStatusTillOnline();
  }
};

const pollUserOnlineStatusTillOnline = (): void => {
  isPollingForUserOnlineStatus = true;
  if (isOnline()) {
    window.PMW.redux.store.dispatch(updateIsUserOnline(true));
    isPollingForUserOnlineStatus = false;
    return;
  }

  window.PMW.redux.store.dispatch(updateIsUserOnline(false));
  userOnlineStatusPollingTimeout = setTimeout(pollUserOnlineStatusTillOnline, 500);
};

export const isOnline = (): boolean => {
  if (!window.navigator.onLine) {
    return false;
  }

  const xhr = new XMLHttpRequest();
  xhr.open('HEAD', window.PMW.util.site_url('posterbuilder/ping'), false);

  try {
    xhr.send();
    return xhr.status >= 200 && xhr.status < 304;
  } catch (e) {
    return false;
  }
};
