import type {ReactElement} from 'react';
import React from 'react';
import {Icon} from '@Components/icon-v2';
import {IconSize} from '@Components/icon-v2/icon.types';
import styles from './add-item-thumbnail.module.scss';

interface AddItemThumbnailContentProps {
  id?: string;
  className?: string;
  iconClassName?: string;
  icon: string;
}

export function AddItemThumbnail({id = '', className = '', iconClassName = '', ...props}: AddItemThumbnailContentProps): ReactElement {
  return (
    <div id={id} className={`${className} ${styles.container} radius-8 flex-center hover-transition-all`}>
      <Icon className={`${styles.icon} ${iconClassName}`} icon={props.icon} size={IconSize.SIZE_ICON_24} />
    </div>
  );
}
