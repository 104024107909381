import type {ReactElement} from 'react';
import React from 'react';
import {Icon} from '@Components/icon-v2';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {Text, TextSize} from '@Components/text';
import {addNewPage} from '@Components/poster-editor/library/poster-editor-page.class';
import {getNumberOfPages} from '@Components/poster-editor/poster-editor-reducer';
import {ENABLE_PAGES} from '@Components/poster-editor/poster-editor.types';
import {useAppSelector} from '@/hooks';

interface PageTopBarProps {
  pageId: string;
}

export function PageTopBar({...props}: PageTopBarProps): ReactElement | null {
  const pagesLength = useAppSelector(getNumberOfPages);
  const pageData = useAppSelector((state) => {
    return state.posterEditor.posterObject?.pages?.pagesHashMap[props.pageId];
  });
  const pageOrder = useAppSelector((state) => {
    const pageIndex = state.posterEditor.posterObject?.pages?.pageOrder.indexOf(props.pageId);
    return pageIndex !== -1 ? pageIndex : undefined;
  });

  const getPageEditingOptions = (): ReactElement => {
    return (
      <div>
        <Icon icon="icon-delete" isDisabled={pagesLength === 1} size={IconSize.SIZE_ICON_16} type={IconType.GHOST_UNCOLORED} shape={IconShape.CIRCLE} onClick={onDeletePage} />
        <Icon icon="icon-duplicate" size={IconSize.SIZE_ICON_16} type={IconType.GHOST_UNCOLORED} shape={IconShape.CIRCLE} onClick={onDuplicatePage} />
        <Icon icon="icon-plus" size={IconSize.SIZE_ICON_16} type={IconType.GHOST_UNCOLORED} shape={IconShape.CIRCLE} onClick={onAddPage} />
      </div>
    );
  };

  const onDeletePage = (): void => {
    window.posterEditor?.whiteboard?.pages.removePage(props.pageId).catch((e) => {
      console.error(e);
    });
  };

  const onDuplicatePage = (): void => {
    console.log('onDuplicatePage');
  };

  const onAddPage = (): void => {
    if (pageOrder !== undefined) {
      addNewPage(pageOrder + 1).catch((e) => {
        console.error(e);
      });
    }
  };

  if (!pageData || pageOrder === undefined || !ENABLE_PAGES) {
    return null;
  }

  return (
    <div className="flex-justify-between flex-items-center spacing-p-t-4">
      <span>
        {pagesLength > 1 ? (
          <>
            <Text val={`Page `} size={TextSize.XSMALL} />
            <Text val={`${pageOrder + 1}`} bold size={TextSize.XSMALL} />
            <Text val={` / ${pagesLength}`} size={TextSize.XSMALL} />
          </>
        ) : null}
      </span>
      {getPageEditingOptions()}
    </div>
  );
}
