import type {ImageItemObject} from '@PosterWhiteboard/items/image-item/image-item.class';
import type {ImageData, TempImageData, VideoData} from '@Libraries/add-media-library';
import {isTempImageData} from '@Libraries/add-media-library';
import type {VideoItemObject} from '@PosterWhiteboard/items/video-item/video-item.class';
import {MULTI_MEDIA_UPLOAD_SOURCES} from '@Libraries/user-media-library';

export const prepImageItemObjectFromImageData = (data: ImageData | TempImageData): Partial<ImageItemObject> => {
  const imageObject: Partial<ImageItemObject> = {};
  if (isTempImageData(data)) {
    imageObject.uploadingImageData = {
      dataUrl: data.dataUrl,
      tempUploadingImageUID: data.uid,
      uploadStartTime: new Date().getTime(),
    };
  } else {
    imageObject.hashedFilename = data.hashedFilename;
    imageObject.imageSource = data.source;
    imageObject.fileExtension = data.extension;
  }
  return imageObject;
};

export const prepVideoItemObjectFromVideoData = (data: VideoData): Partial<VideoItemObject> => {
  const videoObject: Partial<VideoItemObject> = {};
  videoObject.duration = data.duration;
  videoObject.endTime = data.duration;
  videoObject.frameRate = data.frameRate;
  videoObject.videoSource = data.source;
  videoObject.hasTransparency = data.hasTransparency;
  videoObject.hashedFilename = data.hashedFilename;
  videoObject.startTime = 0;
  videoObject.fileExtension = data.extension;

  if (MULTI_MEDIA_UPLOAD_SOURCES.includes(data.source)) {
    videoObject.isMuted = false;
  }

  return videoObject;
};
