import type {Poster} from '@PosterWhiteboard/poster/poster.class';
import {CommonMethods} from '@PosterWhiteboard/common-methods';

export interface FolderObject {
  id: string;
}

export class Folder extends CommonMethods {
  public poster: Poster;
  public id = '';

  constructor(poster: Poster) {
    super();
    this.poster = poster;
  }

  public toObject(): FolderObject {
    return {
      id: this.id,
    };
  }

  public updateFromObject(object: Partial<FolderObject>): void {
    this.copyVals(object);
  }
}
