import {isMacOs} from 'react-device-detect';

/**
 * Expects an array of key names in the Windows shortcut style ('Ctrl' etc.) and returns a string representing a keyboard
 * shortcut based on the user's operating system. Windows and OSX have different styles of displaying keyboard shortcuts.
 *
 * @param keyCombinations
 * @return string
 */
export const makeShortcut = (keyCombinations: Array<string>) => {
  let separator = '+';
  if (isMacOs) {
    separator = '';
    for (let i = 0; i < keyCombinations.length; i++) {
      const key = keyCombinations[i];
      switch (key) {
        case 'Ctrl':
          keyCombinations[i] = '⌘';
          break;

        case 'Shift':
          keyCombinations[i] = '⇧';
          break;

        case 'Alt':
          keyCombinations[i] = '⌥';
          break;

        case 'Tab':
          keyCombinations[i] = '⇥ ';
          break;

        case 'Del':
          keyCombinations[i] = '⌫';
          break;

        default:
          break;
      }
    }
  }

  return keyCombinations.join(separator);
};
