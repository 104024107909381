import type {MouseEventHandler, ReactElement} from 'react';
import React from 'react';
import type {BaseControlledListItem} from '@Components/controlled-list/controlled-list.types';
import { ControlledListItemSize} from '@Components/controlled-list/controlled-list.types';
import {Text, TextSize} from '@Components/text';
import {Icon} from '@Components/icon-v2';
import {IconSize} from '@Components/icon-v2/icon.types';
import styles from './controlled-list-item-type-7.module.scss';

export interface ControlledListItemType7Props extends BaseControlledListItem {
  icon?: string;
  iconClasses?: string;
  textClasses?: string;
  color?: ColorTypes;
  shortcut?: string;
  rightIcon?: string;
  rightIconClassName?: string;
  isDisabled?: boolean;
  onRightIconClick?(id?: string): void;
}

export enum ColorTypes {
  default = 'default',
  danger = 'danger',
}

export function ControlledListItemType7({
  id = '',
  className = '',
  isSelected = false,
  icon = '',
  color = ColorTypes.default,
  textClasses = '',
  shortcut = '',
  rightIcon = '',
  rightIconClassName = '',
  isDisabled = false,
  onClick = (): void => {},
  listSize = ControlledListItemSize.SMALL,
  ...props
}: ControlledListItemType7Props): ReactElement {
  const getTextColorClass = (): string => {
    if (color === ColorTypes.default) {
      return isSelected ? 'content-primary' : 'content-body';
    }
    if (color === ColorTypes.danger) {
      return styles.danger;
    }
    return '';
  };

  const getIconSize = (): IconSize => {
    switch (listSize) {
      case ControlledListItemSize.MEDIUM:
        return IconSize.SIZE_ICON_20;
      case ControlledListItemSize.SMALL:
      default:
        return IconSize.SIZE_ICON_16;
    }
  };

  const getTextSize = (): TextSize => {
    switch (listSize) {
      case ControlledListItemSize.MEDIUM:
        return TextSize.SMALL;
      case ControlledListItemSize.SMALL:
      default:
        return TextSize.XSMALL;
    }
  };

  const rightIconClickCallback: MouseEventHandler = (e): void => {
    if (rightIcon && !!props.onRightIconClick) {
      e.stopPropagation();
      props.onRightIconClick(id);
    }
  };

  return (
    <li
      onClick={(event): void => {
        return onClick(id, event);
      }}
      className={`${styles.listItemContainer} ${
        listSize === ControlledListItemSize.SMALL ? styles.small : styles.medium
      } hover-transition-all radius-2 spacing-p-3 flex-row-justify-between ${className} ${isDisabled ? `${styles.disabled} disabled` : ''}`}
    >
      <div className="flex-center">
        <Icon icon={icon} className={`${styles.icon} ${color === ColorTypes.danger ? styles.danger : ''} spacing-m-r-2 ${props.iconClasses ?? ''}`} size={getIconSize()} />
        <Text className={`${isDisabled ? 'disabled' : ''} ${textClasses} ${getTextColorClass()} ${styles.listText}`} val={props.text} size={getTextSize()} bold={isSelected} />
      </div>
      {rightIcon ? (
        <Icon onClick={rightIconClickCallback} className={`${shortcut ? 'spacing-m-r-1' : ''} ${rightIconClassName}`} icon={rightIcon} size={IconSize.SIZE_ICON_16} />
      ) : null}
      {shortcut ? <Text className="content-disabled" val={shortcut} size={TextSize.XXSMALL} bold={isSelected} /> : null}
    </li>
  );
}
