import {CommonMethods} from '@PosterWhiteboard/common-methods';
import type {UserObject} from '@PosterWhiteboard/user/user.types';
import {USER_PREMIUM_LEVELS} from '@Utils/user.util';

export class User extends CommonMethods {
  public id = '-1';
  public fbId = '';
  public name = '';
  public type = '';
  public watermark?: string;
  public premiumLevel: USER_PREMIUM_LEVELS = USER_PREMIUM_LEVELS.PAYG;
  public preferredLanguage = '';
  public verificationNeededStatus = false;

  constructor(opts: Partial<UserObject> = {}) {
    super();
    this.copyVals({
      ...opts,
      watermark: opts.watermark === '-1' ? '' : opts.watermark,
    });
  }

  public isDesigner(): boolean {
    return this.type === 'designer';
  }

  public toObject(): UserObject {
    return {
      id: this.id,
      fbId: this.fbId,
      name: this.name,
      type: this.type,
      watermark: this.watermark,
      premiumLevel: this.premiumLevel,
      preferredLanguage: this.preferredLanguage,
      verificationNeededStatus: this.verificationNeededStatus,
    };
  }
}
