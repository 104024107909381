// @ts-expect-error waiting for fabric to fix this https://github.com/fabricjs/fabric.js/issues/10109
import {initAligningGuidelines} from '@postermywall/fabricjs-2/extensions';
import {noop} from '@Utils/general.util';
import {Canvas} from '@postermywall/fabricjs-2';
import type {Page} from './page.class';

const ALIGNING_LINE_COLOR = '#FF5177';

export class RenderAlignmentGuidelines {
  public page: Page;
  public disableAlignmentGuides = noop;

  constructor(page: Page) {
    this.page = page;
    if (this.page.poster.alignmentGuides.enabled) {
      this.enableAligningGuidelines();
    }
  }

  public enableAligningGuidelines(): void {
    if (this.page.fabricCanvas instanceof Canvas) {
      this.disableAlignmentGuides = initAligningGuidelines(this.page.fabricCanvas, {
        color: ALIGNING_LINE_COLOR,
      });
    }
  }

  public disableAligningGuidelines(): void {
    this.disableAlignmentGuides();
    this.disableAlignmentGuides = noop;
  }
}
