import type {ItemObject, ItemType} from '@PosterWhiteboard/items/item/item.types';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import type {Page} from '@PosterWhiteboard/page/page.class';
import type {VideoItem} from '@PosterWhiteboard/items/video-item/video-item.class';
import type {CornerPoints} from '@Utils/math.util';
import {getCommonAreaOfRectangles} from '@Utils/math.util';
import type {ImageItem} from '@PosterWhiteboard/items/image-item/image-item.class';
import type {SlideshowItem} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.class';
import type {TextItem} from '@PosterWhiteboard/items/text-item/text-item.class';
import {AddItem} from '@PosterWhiteboard/page/add-item.class';
import type {UpdateFromObjectOpts} from '@PosterWhiteboard/common.types';
import {hideLoading, showLoading} from '@Libraries/loading-toast-library';
import {openMessageModal} from '@Modals/message-modal';
import {ReplaceItem} from '@PosterWhiteboard/page/replace-item.class';
import type {StickerItem} from '@PosterWhiteboard/items/sticker-item.class';
import {AnimationType} from '@PosterWhiteboard/animation/animation.class';
import {MAX_STICKER_ITEMS, MAX_VIDEO_ITEMS} from '@PosterWhiteboard/page/page.types';
import {createItemFromObject} from '@PosterWhiteboard/items/item/item-factory';
import type {VideoSlideItem} from '@PosterWhiteboard/items/slideshow-item/slide-items/video-slide-item.class';
import type {FabricObject, Group} from '@postermywall/fabricjs-2';
import type {TranscriptItem} from '@PosterWhiteboard/items/transcript-item/transcript-item';
import type {TranscriptItemObject} from '@PosterWhiteboard/items/transcript-item/transcript-item.types';
import type {DeepPartial} from '@/global';

export interface PageItemsObject {
  itemsHashMap: Record<any, ItemObject>;
  itemsOrder: Array<string>;
}

export class PageItems {
  public itemsHashMap: Record<string, ItemType> = {};
  public page: Page;
  public addItems: AddItem;
  public replaceItems: ReplaceItem;

  public constructor(page: Page) {
    this.page = page;
    this.addItems = new AddItem(page);
    this.replaceItems = new ReplaceItem(page);
  }

  public getItemIdsInOrder(): Array<string> {
    const objs = this.page.fabricCanvas.getObjects();
    const itemsInOrder = [];

    for (let i = 0; i < objs.length; i++) {
      if (objs[i].__PMWID) {
        itemsInOrder.push(objs[i].__PMWID);
      }
    }
    return itemsInOrder;
  }

  public getItemOrder(itemId: string): number {
    return this.page.fabricCanvas.getObjects().indexOf(this.itemsHashMap[itemId]?.fabricObject);
  }

  public addItem(newItem: ItemType): void {
    this.itemsHashMap[newItem.uid] = newItem;
  }

  public getLongestVideoItem(): VideoItem | null {
    let longestVideo: VideoItem | null = null;
    const itemsIds = Object.keys(this.itemsHashMap);

    itemsIds.forEach((itemId) => {
      if (this.itemsHashMap[itemId].isVideo() && (!longestVideo || this.itemsHashMap[itemId].getPlayDuration() > longestVideo.getPlayDuration())) {
        longestVideo = this.itemsHashMap[itemId];
      }
    });

    return longestVideo;
  }

  public getLongestStreamingSlideshowItem(): SlideshowItem | undefined {
    let longestSlideshowItem: SlideshowItem | undefined;
    const itemsIds = Object.keys(this.itemsHashMap);

    itemsIds.forEach((itemId) => {
      const item = this.itemsHashMap[itemId];
      if (item.isSlideshow() && item.isStreamingItem() && (!longestSlideshowItem || item.getPlayDuration() > longestSlideshowItem.getPlayDuration())) {
        longestSlideshowItem = item;
      }
    });

    return longestSlideshowItem;
  }

  public getLongestVideoItemDuration(): number {
    const item = this.getLongestVideoItem();
    return item ? item.getDuration() : 0;
  }

  public getItemForFabricObject(fabricItem: FabricObject): ItemType | undefined {
    return this.itemsHashMap[fabricItem.__PMWID];
  }

  public getLongestSlideshowItemDuration(): number {
    const item = this.getLongestStreamingSlideshowItem();
    return item ? item.getDuration() : 0;
  }

  public hasVideoItem(): boolean {
    for (const [, item] of Object.entries(this.itemsHashMap)) {
      if (item.isVideo()) {
        return true;
      }
    }
    return false;
  }

  public hasClickableItem(): boolean {
    return Object.values(this.itemsHashMap).some((item) => {
      return item.hasClickableLink();
    });
  }

  public hasVideoContainingSlideshowItem(): boolean {
    for (const [, item] of Object.entries(this.itemsHashMap)) {
      if (item.isSlideshow() && item.slides.hasVideoSlide()) {
        return true;
      }
    }
    return false;
  }

  public hasStreamingSlideShowItem(): boolean {
    const itemsIds = Object.keys(this.itemsHashMap);

    for (const itemId of itemsIds) {
      const item = this.itemsHashMap[itemId];
      if (item.isSlideshow() && item.isStreamingItem()) {
        return true;
      }
    }
    return false;
  }

  public hasItem(uid: string): boolean {
    return this.itemsHashMap[uid] !== undefined;
  }

  public getVideoContainingItems(): Array<ItemType> {
    return Object.values(this.itemsHashMap).filter((item) => {
      return item.isVideo() || (item.isSlideshow() && item.slides.hasVideoSlide()) ? item : null;
    });
  }

  public getNumberOfVideoContainingItems(): number {
    return this.getVideoContainingItems().length;
  }

  public getItem(uid: string): ItemType | undefined {
    if (!this.hasItem(uid)) {
      return undefined;
    }

    return this.itemsHashMap[uid];
  }

  public getGettyImageCount(): number {
    let count = 0;

    const itemsIds = Object.keys(this.itemsHashMap);
    for (const itemId of itemsIds) {
      const item = this.itemsHashMap[itemId];
      if (item.isImage() && item.visible && item.isNonPurchasedGettyImage()) {
        count += 1;
      } else if (item.isSlideshow()) {
        count += item.slides.getUnpuchasedGettyImageSlidesCount();
      }
    }

    return count;
  }

  public getExtractedGettyStickerCount(): number {
    let count = 0;

    const itemsIds = Object.keys(this.itemsHashMap);
    for (const itemId of itemsIds) {
      const item = this.itemsHashMap[itemId];
      if (item.isImage() && item.visible && item.isNonPurchasedExtractedGettySticker()) {
        count += 1;
      }
    }

    return count;
  }

  public getGettyVideoCount(): number {
    let count = 0;

    const itemsIds = Object.keys(this.itemsHashMap);
    for (const itemId of itemsIds) {
      const item = this.itemsHashMap[itemId];
      if (item.isVideo() && item.hasGettyContent()) {
        count += 1;
      } else if (item.isSlideshow()) {
        count += item.slides.getGettyVideoSlidesCount();
      }
    }

    return count;
  }

  updateSlideshowIntroAnimationPadding(): void {
    for (const [, item] of Object.entries(this.itemsHashMap)) {
      if (item.isSlideshow()) {
        item.updateIntroAnimationPadding();
      }
    }
  }

  public toObject(): PageItemsObject {
    const itemObjects: Record<string, ItemObject> = {};

    for (const [key, item] of Object.entries(this.itemsHashMap)) {
      itemObjects[key] = item.toObject();
    }

    return {
      itemsHashMap: itemObjects,
      itemsOrder: this.getItemIdsInOrder(),
    };
  }

  public async updateFromObject(
    itemsObject: DeepPartial<PageItemsObject> = {},
    {updateRedux = true, undoable = true, refreshActiveSelection = false, checkForDurationUpdate = false}: UpdateFromObjectOpts = {}
  ): Promise<void> {
    // delete items that are in this page but not in the itemsObject
    for (const [uid] of Object.entries(this.itemsHashMap)) {
      if (itemsObject.itemsHashMap === undefined || itemsObject.itemsHashMap[uid] === undefined) {
        this.doRemoveItem(uid);
      }
    }

    // Update/Add items from itemsObject
    const updateFromObjectPromises = [];
    const selectedViews = this.page.activeSelection.getSelectedViews() as Array<FabricObject | Group>;
    let itemsAreInActiveSelection = false;
    if (itemsObject.itemsHashMap) {
      const addItemPromises = [];

      if (refreshActiveSelection && selectedViews.length > 1) {
        itemsAreInActiveSelection = true;
        this.page.poster.itemsMultiSelect.isActiveSelectionModificationProcessing = true;
        this.page.activeSelection.clearSelection();
      }
      for (const [uid, itemObject] of Object.entries(itemsObject.itemsHashMap)) {
        if (itemObject) {
          if (this.itemsHashMap[uid] !== undefined) {
            updateFromObjectPromises.push(
              this.itemsHashMap[uid].updateFromObject(itemObject, {
                updateRedux: false,
                undoable: false,
                checkForDurationUpdate,
              })
            );
          } else {
            const addItemPromise = new Promise<void>((resolve, reject) => {
              showLoading(`addItem${uid}`);
              createItemFromObject(this.page, itemObject)
                .then(async (itemToAdd) => {
                  await this.page.addItem(itemToAdd, {
                    updateRedux: true,
                    undoable: false,
                    checkForDurationUpdate,
                  });
                  resolve();
                })
                .catch(reject)
                .finally(() => {
                  hideLoading(`addItem${uid}`);
                });
            });
            if (this.page.poster.mode.isGeneration()) {
              try {
                await addItemPromise;
              } catch (e) {
                console.error(e);
                throw e;
              }
            } else {
              addItemPromises.push(addItemPromise);
            }
          }
        }
      }

      await Promise.allSettled(addItemPromises);
    }

    if (itemsObject.itemsOrder) {
      itemsObject.itemsOrder.forEach((itemId, i) => {
        if (itemId) {
          const item = this.itemsHashMap[itemId];
          if (item) {
            this.moveItemTo(item, i);
          }
        }
      });
    }

    await Promise.all(updateFromObjectPromises);

    if (itemsAreInActiveSelection) {
      const filteredViews = this.page.getFilteredExistingViews(selectedViews);
      if (filteredViews.length) {
        this.page.activeSelection.selectFabricObjects(filteredViews);
      }
      this.page.poster.itemsMultiSelect.isActiveSelectionModificationProcessing = false;
    }

    this.checkForIntroAnimationOnNoItems();

    if (undoable) {
      this.page.poster.history.addPosterHistory();
    }
    if (updateRedux) {
      this.page.poster.redux.updateReduxData();
    }
  }

  public moveItemTo(item: ItemType, index: number): void {
    const objs = this.page.fabricCanvas.getObjects();
    let currentIndexForPMWItems = 0;

    for (const [i, obj] of objs.entries()) {
      if (!obj.bindedObjectPmwId) {
        if (currentIndexForPMWItems === index) {
          this.page.fabricCanvas.moveObjectTo(item.fabricObject, i);
          item.updateBoundItemsZIndex();
          return;
        }
      }
      if (obj.__PMWID) {
        currentIndexForPMWItems += 1;
      }
    }

    this.page.fabricCanvas.moveObjectTo(item.fabricObject, objs.length);
    item.updateBoundItemsZIndex();
  }

  public bringItemForward(itemId: string): void {
    this.page.fabricCanvas.bringObjectForward(this.itemsHashMap[itemId].fabricObject, true);
    this.onZIndexModified();
  }

  public sendItemBackwards(itemId: string): void {
    this.page.fabricCanvas.sendObjectBackwards(this.itemsHashMap[itemId].fabricObject, true);
    this.onZIndexModified();
  }

  public bringItemToFront(itemId: string): void {
    this.page.fabricCanvas.bringObjectToFront(this.itemsHashMap[itemId].fabricObject);
    this.onZIndexModified();
  }

  public sendItemToBack(itemId: string): void {
    this.page.fabricCanvas.sendObjectToBack(this.itemsHashMap[itemId].fabricObject);
    this.onZIndexModified();
  }

  public updateBoundItemsZIndices(): void {
    for (const [, item] of Object.entries(this.itemsHashMap)) {
      item.updateBoundItemsZIndex();
    }
  }

  private onZIndexModified(): void {
    this.updateBoundItemsZIndices();
    this.page.poster.history.addPosterHistory();
    this.page.poster.redux.updateReduxData();
  }

  public removeItem(itemUid: string, undoable = true): void {
    const item = this.getItem(itemUid);
    if (item) {
      if ((item.isVideo() || item.isTranscript()) && this.page.poster.mode.isRegen()) {
        openMessageModal({
          title: window.i18next.t('pmwjs_delete_item_disabled_title'),
          text: window.i18next.t('pmwjs_delete_item_disabled_message'),
        });
        return;
      }

      const isItemStreamingMediaItem = item.isStreamingMediaItem();
      this.doRemoveItem(itemUid);

      this.checkForIntroAnimationOnNoItems();

      if (isItemStreamingMediaItem) {
        this.page.calculateAndUpdatePageDuration();
      }
      if (item.isPremium()) {
        this.page.pageWatermark.refreshWatermark();
      }
      if (undoable) {
        this.page.poster.history.addPosterHistory();
      }
      this.page.poster.redux.updateReduxData();
    }
  }

  public checkForIntroAnimationOnNoItems(): void {
    if (Object.keys(this.itemsHashMap).length === 0 && this.page.introAnimation.hasIntroAnimation()) {
      this.page.introAnimation.updateFromObject({animation: {type: AnimationType.NONE}}, {undoable: false});
    }
  }

  private doRemoveItem(itemUid: string): void {
    if (this.hasItem(itemUid)) {
      this.itemsHashMap[itemUid].onRemove();
      this.page.activeSelection.removeItemFromSelection(this.itemsHashMap[itemUid].fabricObject);
      this.page.fabricCanvas.remove(this.itemsHashMap[itemUid].fabricObject);
      delete this.itemsHashMap[itemUid];
    }
  }

  public getItems(): Array<ItemType> {
    return Object.values(this.itemsHashMap);
  }

  public getTextItems(): Array<TextItem> {
    const items = [];
    for (const [, item] of Object.entries(this.itemsHashMap)) {
      if (item.isText()) {
        items.push(item);
      }
    }
    return items;
  }

  public getImageItems(): Array<ImageItem> {
    const items = [];
    for (const [, item] of Object.entries(this.itemsHashMap)) {
      if (item.isImage()) {
        items.push(item);
      }
    }
    return items;
  }

  public hasMaxNumberOfVideoContainingItems(): boolean {
    return this.getNumberOfVideoContainingItems() >= MAX_VIDEO_ITEMS;
  }

  public hasMaxNumberOfStickerItems(): boolean {
    return this.getStickerItems().length >= MAX_STICKER_ITEMS;
  }

  public hasItems(): boolean {
    return Object.keys(this.itemsHashMap).length > 0;
  }

  public getStickerItems(): Array<StickerItem> {
    const stickers: Array<StickerItem> = [];
    for (const [, item] of Object.entries(this.itemsHashMap)) {
      if (item.isSticker()) {
        stickers.push(item);
      }
    }
    return stickers;
  }

  public getSlideshowItems(): Array<SlideshowItem> {
    const slideshowItems: Array<SlideshowItem> = [];
    for (const [, item] of Object.entries(this.itemsHashMap)) {
      if (item.isSlideshow()) {
        slideshowItems.push(item);
      }
    }
    return slideshowItems;
  }

  public getVideoSlides(): Array<VideoSlideItem> {
    const slideshowItems: Array<VideoSlideItem> = [];
    for (const [, item] of Object.entries(this.itemsHashMap)) {
      if (item.isSlideshow()) {
        slideshowItems.push(...item.slides.getVideoSlides());
      }
    }
    return slideshowItems;
  }

  public getVideoItems(): Array<VideoItem> {
    const videoItems: Array<VideoItem> = [];
    for (const [, item] of Object.entries(this.itemsHashMap)) {
      if (item.isVideo()) {
        videoItems.push(item);
      }
    }
    return videoItems;
  }

  public getGenerationSeekableItems(): Array<StickerItem | TranscriptItem> {
    const generationSeekableItems: Array<StickerItem | TranscriptItem> = [];
    for (const [, item] of Object.entries(this.itemsHashMap)) {
      if (item.isSticker() || item.isTranscript()) {
        generationSeekableItems.push(item);
      }
    }
    return generationSeekableItems;
  }

  public isVideoItemUsedAsBackground(): boolean {
    if (!this.hasVideoItem()) {
      return false;
    }

    const canvasVertices = this.page.getCanvasCornerPoints();
    const MAX_NON_VIDEO_BACKGROUND_RATIO = 0.7;
    let graphicItemArea;
    let graphicItemAreaRatio;

    const itemsIds = Object.keys(this.itemsHashMap);

    for (const itemId of itemsIds) {
      if (this.itemsHashMap[itemId].isVideo()) {
        const videoItem = this.itemsHashMap[itemId];
        const graphicItemVertices = videoItem.fabricObject.getCornerPoints(videoItem.fabricObject.getCenterPoint());
        // reformatting them according to doPolygonsIntersect() params format requirement
        const graphicItemCornerPoints: CornerPoints = {
          tl: {
            x: graphicItemVertices.tl.x,
            y: graphicItemVertices.tl.y,
          },
          tr: {
            x: graphicItemVertices.tr.x,
            y: graphicItemVertices.tr.y,
          },
          br: {
            x: graphicItemVertices.br.x,
            y: graphicItemVertices.br.y,
          },
          bl: {
            x: graphicItemVertices.bl.x,
            y: graphicItemVertices.bl.y,
          },
        };
        graphicItemArea = getCommonAreaOfRectangles(canvasVertices, graphicItemCornerPoints);
        graphicItemAreaRatio = graphicItemArea / (canvasVertices.bl.x * canvasVertices.bl.y);

        if (graphicItemAreaRatio > MAX_NON_VIDEO_BACKGROUND_RATIO) {
          return true;
        }
      }
    }
    return false;
  }

  public getTopItem(): ItemType | undefined {
    const objs = this.page.fabricCanvas.getObjects();

    for (let i = objs.length - 1; i >= 0; i--) {
      if (objs[i].__PMWID) {
        return this.itemsHashMap[objs[i].__PMWID];
      }
    }
    return undefined;
  }

  public getBottomItem(): ItemType | undefined {
    const objs = this.page.fabricCanvas.getObjects();

    for (const obj of objs) {
      if (obj.__PMWID) {
        return this.itemsHashMap[obj.__PMWID];
      }
    }
    return undefined;
  }

  public getNumberOfItemsOnPage(): number {
    const itemsIds = Object.keys(this.itemsHashMap);
    return itemsIds.length;
  }

  public sendBackwardsSelectedItem(): void {
    this.sendItemBackwards(this.page.getSelectedItems()[0].uid);
  }

  public bringForwardSelectedItem(): void {
    this.bringItemForward(this.page.getSelectedItems()[0].uid);
  }

  public bringToFrontSelectedItem(): void {
    this.bringItemToFront(this.page.getSelectedItems()[0].uid);
  }

  public sendToBackSelectedItem(): void {
    this.sendItemToBack(this.page.getSelectedItems()[0].uid);
  }

  public hasLockedItems(): boolean {
    for (const [, item] of Object.entries(this.itemsHashMap)) {
      if (item.isLocked()) {
        return true;
      }
    }
    return false;
  }

  public unLockAllLockedItems(): void {
    const itemsHashMapObject = this.toObject().itemsHashMap;

    for (const [, item] of Object.entries(itemsHashMapObject)) {
      if (item.lockMovement) {
        item.lockMovement = false;
      }
    }

    void this.updateFromObject({itemsHashMap: itemsHashMapObject});
  }

  public getNumberOfTranscriptItems(): number {
    const itemsHashMapObject = this.toObject().itemsHashMap;
    let numItems = 0;

    for (const [, item] of Object.entries(itemsHashMapObject)) {
      if (item.gitype === ITEM_TYPE.TRANSCRIPT) {
        numItems += 1;
      }
    }

    return numItems;
  }

  public getTranscriptItemIds(): Array<string> {
    const itemsHashMapObject = this.toObject().itemsHashMap;
    const ids: Array<string> = [];

    for (const [, item] of Object.entries(itemsHashMapObject)) {
      if (item.gitype === ITEM_TYPE.TRANSCRIPT) {
        ids.push(item.uid);
      }
    }

    return ids;
  }

  public getAllTranscriptItems(): Record<string, TranscriptItemObject> {
    const itemsHashMapObject = this.toObject().itemsHashMap;
    const transcriptItemsHash: Record<string, TranscriptItemObject> = {};
    for (const [key, item] of Object.entries(itemsHashMapObject)) {
      if (item.gitype === ITEM_TYPE.TRANSCRIPT) {
        transcriptItemsHash[key] = item as TranscriptItemObject;
      }
    }

    return transcriptItemsHash;
  }

  public async trimAllTranscriptItemsExceedingPageDuration(): Promise<void> {
    const promises: Array<Promise<void>> = [];
    for (const [, item] of Object.entries(this.itemsHashMap)) {
      if (item.isTranscript()) {
        promises.push(item.trimSubtitlesExceedingDuration(this.page.getDuration()));
      }
    }

    await Promise.all(promises);
  }
}
