import {noop} from '@Utils/general.util';

export const asyncSleep = (time: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
};

export const debounce = <T extends (...args: any[]) => any>(func: T, wait: number, onStart: () => void = noop): T => {
  let timeoutId: number | undefined;
  let isDebouncing = false;

  return function (this: any, ...args: any[]) {
    if (!isDebouncing) {
      onStart();
      isDebouncing = true;
    }

    clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      func.apply(this, args);
      isDebouncing = false;
    }, wait);
  } as T;
};
