import type {Poster} from '@PosterWhiteboard/poster/poster.class';
import {CommonMethods} from '@PosterWhiteboard/common-methods';

export interface GridObject {
  enabled: boolean;
  size: number;
}

const DEFAULT_GRID_SIZE = 25;

export class Grid extends CommonMethods {
  private poster: Poster;
  public enabled = false;
  public size = DEFAULT_GRID_SIZE;

  constructor(poster: Poster) {
    super();
    this.poster = poster;
  }

  public toObject(): GridObject {
    return {
      enabled: this.enabled,
      size: this.size,
    };
  }

  public updateFromObject(gridObject: Partial<GridObject>): void {
    this.copyVals(gridObject);
    this.poster.redux.updateReduxData();
  }
}
