import type {ReactElement} from 'react';
import React, {useEffect, useRef, useState} from 'react';
import {ControlledListDividerItem} from '@Components/controlled-list/components/controlled-list-divider-item';
import {Button, Size, Type} from '@Components/button';
import {v4 as uuidv4} from 'uuid';
import {Page} from '@PosterWhiteboard/page/page.class';
import {updateSpellCheckPopUpOpenState, updateTextSelectionPopUpOpenState} from '@Components/poster-editor/components/text-item-popup/text-item-popup-slice';
import {TextItem} from '@PosterWhiteboard/items/text-item/text-item.class';
import {addToDictionary, updateSpellCheckCache} from '@Libraries/spell-check-library';
import {SpellCheckSettingsButton} from '@Components/spell-check-ignore-button';
import {degreesToRadians} from '@Utils/math.util';
import {SlideshowItem} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.class';
import {updateSpellCheckSettingsState} from '@Components/poster-editor/poster-editor-reducer';
import {Icon} from '@Components/icon-v2';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {useSingleActivePosterItem} from '@Hooks/poster-editor/useSingleActivePosterItem';
import {copyToClipboard} from '@Utils/clipboard.util';
import {Canvas, Textbox} from '@postermywall/fabricjs-2';
import {useAppDispatch, useAppSelector} from '@/hooks';
import styles from './text-item-popup.module.scss';

interface IndexLocation {
  lineIndex: number;
  charIndex: number;
}

interface PositionalParams {
  left?: number;
  top?: number;
}

interface CornerPoint {
  x: number;
  y: number;
}

const CORNER_PADDING = 5;
const SUGGESTION_POPUP_MAX_WIDTH = 200;
const SUGGESTION_POPUP_HEIGHT = 110;
const SELECTION_POPUP_HEIGHT = 48;
const SELECTION_POPUP_EXPANDED_HEIGHT = 100;

export function TextItemPopup(): ReactElement | null {
  const popupState = useAppSelector((state) => {
    return state.textItemPopUpMenu;
  });
  const isSpellcheckEnabled = useAppSelector((state) => {
    return state.spellCheck.isSpellCheckEnabled;
  });
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
  const activeCanvas = currentPage?.fabricCanvas;
  const activeItem = useSingleActivePosterItem();
  const [isTextOptionExpanded, setIsTextOptionExpanded] = useState(false);

  useEffect(() => {
    if (!isSpellcheckEnabled) {
      closeSpellCheckPopup();
    }
  }, [isSpellcheckEnabled]);

  const popUpRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const closeSpellCheckPopup = (): void => {
    dispatch(updateSpellCheckSettingsState(false));
    dispatch(updateSpellCheckPopUpOpenState(false));
  };

  if (!(activeCanvas instanceof Canvas)) {
    return null;
  }

  const closeTextSelectionPopup = (): void => {
    activeCanvas?.requestRenderAll();
    dispatch(updateTextSelectionPopUpOpenState(false));
    setIsTextOptionExpanded(false);
  };

  const applyFocusOnTextarea = (): void => {
    const activeObject = activeCanvas?.getActiveObject();
    if (
      (activeItem?.isText() || activeItem?.isSlideshow()) &&
      activeItem.clone &&
      activeItem.clone.hiddenTextarea &&
      activeCanvas &&
      activeObject &&
      activeObject instanceof Textbox
    ) {
      activeItem.clone.hiddenTextarea.focus();
    }
  };

  const getSuggestions = (): Array<ReactElement> | null => {
    const suggestedWordsReactComponent: Array<ReactElement> = [];

    if (popupState.words) {
      for (const word of popupState.words) {
        const id = uuidv4();
        suggestedWordsReactComponent.push(
          <Button
            key={id}
            text={word}
            customClasses={styles.suggestedWordsButton}
            textClasses={`body-s-bold ${styles.suggestedWords}`}
            type={Type.GHOST}
            onClick={onSuggestionClicked}
          />
        );
      }
      return suggestedWordsReactComponent;
    }
    return null;
  };

  const getSuggestionsElement = (): ReactElement | null => {
    if (popupState.words?.length) {
      return (
        <li id="popup-btn-suggestions" className="spacing-p-0">
          <div className={`${styles.suggestions} flex-items-center flex-h-row`}>{getSuggestions()}</div>
          <ul>
            <ControlledListDividerItem id="spellCheckDivider" />
          </ul>
        </li>
      );
    }
    return null;
  };

  const onSuggestionClicked = (e: React.MouseEvent<HTMLElement>): void => {
    const target = e.target as HTMLElement;
    const start = popupState.wordPosition?.start;
    const end = popupState.wordPosition?.end;
    const selectedItem = window.posterEditor?.whiteboard?.getSelectedItems()[0];

    if (typeof start === 'number' && typeof end === 'number' && selectedItem && (selectedItem instanceof TextItem || selectedItem instanceof SlideshowItem)) {
      const activeObjects = window.posterEditor?.whiteboard?.getSelectedViews();
      if (activeObjects) {
        const suggestion = target.innerText;
        const prevText = selectedItem.getActiveText().toString();
        const updatedText = prevText.substring(0, start) + suggestion + prevText.substring(end, prevText.length);

        selectedItem.updateActiveText(updatedText);
        updateSpellCheckCache(suggestion, true);
        applyFocusOnTextarea();
        closeSpellCheckPopup();
      }
    }
  };

  const onIgnore = async (): Promise<void> => {
    const start = popupState.wordPosition?.start;
    const end = popupState.wordPosition?.end;

    const selectedItem = window.posterEditor?.whiteboard?.getSelectedItems()[0];
    if (typeof start === 'number' && typeof end === 'number' && selectedItem && (selectedItem.isText() || selectedItem.isSlideshow())) {
      const activeObjects = window.posterEditor?.whiteboard?.getSelectedViews();
      if (activeObjects) {
        const text = selectedItem.getActiveText().substring(start, end).trim();
        await addToDictionary(text);
        updateSpellCheckCache(text, true);
        applyFocusOnTextarea();
        const fabricTextbox = activeObjects[0];
        await selectedItem?.page?.spellCheck?.checkSpell(fabricTextbox as Textbox);
      }
    }
    closeSpellCheckPopup();
  };

  const getMenuPosition = (): PositionalParams => {
    const currentPageId = window.posterEditor?.whiteboard?.getCurrentPageId() ?? '';
    const canvasContainerElementOfFirstPage = Page.getFirstCanvasContainerHTML();
    const canvasContainerElementOfCurrentPage = Page.getCanvasContainerHTMLForPageId(currentPageId);
    const target = popupState.target as Textbox;
    const {angle} = target;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore PMW added property.
    const pointer = target.getCharOffset(angle > 0 && angle < 90 ? popupState.wordPosition?.end : popupState.wordPosition?.start) as Record<string, number>;
    const {lineIndex} = calcLocation();
    const lineHeight = target.getHeightOfLine(lineIndex) * target.scaleY;
    const posterScale = window.posterEditor?.whiteboard?.scaling.scale ?? 1;

    const pageOffsetHeight = Page.getPrePageHTMLContainerForPageId(currentPageId)?.offsetHeight ?? 0;
    const canvasContainerElementTopOffset = (canvasContainerElementOfCurrentPage?.offsetTop ?? 0) - (canvasContainerElementOfFirstPage?.offsetTop ?? 0);
    let posX = (target.aCoords.tl.x + pointer.x * target.scaleX) * posterScale;
    let posY = (target.aCoords.tl.y + (lineIndex + (popupState.openSpellCheckPopUpMenu ? 1 : 0)) * lineHeight) * posterScale;
    let position = null;

    if (angle) {
      position = getPositionForRotatedTextbox(target, pointer);
      posX = position.x;
      posY = position.y;
    }
    position = getPositionForCornercases(target, posX, posY);

    return {
      left: position.x,
      top: position.y + pageOffsetHeight + canvasContainerElementTopOffset - (popupState.openTextSelectionPopupMenu && !angle ? getDefaultPopupHeight() : 0),
    };
  };

  const getPositionForRotatedTextbox = (target: Textbox, pointer: Record<string, number>): CornerPoint => {
    let {lineIndex} = target.get2DCursorLocation();
    const lineHeight = target.getHeightOfLine(lineIndex) * target.scaleY;
    const {angle} = target;
    const radAngle = degreesToRadians(angle);
    const textAngle = 90 - angle;
    const textRadAngle = degreesToRadians(textAngle);
    const posterScale = window.posterEditor?.whiteboard?.scaling.scale ?? 1;

    lineIndex += angle < 180 ? 0.5 : 0;
    const left = target.aCoords.tl.x - lineIndex * lineHeight * Math.cos(textRadAngle);
    const top = target.aCoords.tl.y + lineIndex * lineHeight * Math.sin(textRadAngle);
    const posX = (left + pointer.x * target.scaleX * Math.cos(radAngle)) * posterScale;
    let posY = (top + pointer.x * target.scaleX * Math.sin(radAngle)) * posterScale;
    posY += angle >= 270 ? Math.abs(lineHeight * Math.cos(radAngle)) / 2 : 0;
    return {x: posX, y: posY};
  };

  const getDefaultPopupHeight = (): number => {
    if (popupState.openSpellCheckPopUpMenu) {
      return SUGGESTION_POPUP_HEIGHT;
    }
    if (popupState.openTextSelectionPopupMenu && isTextOptionExpanded) {
      return SELECTION_POPUP_EXPANDED_HEIGHT;
    }
    return SELECTION_POPUP_HEIGHT;
  };

  const getPositionForCornercases = (target: Textbox, posX: number, posY: number): CornerPoint => {
    const canvas = window.posterEditor?.whiteboard?.getCurrentPage().fabricCanvas;
    if (canvas) {
      const posterScale = window.posterEditor?.whiteboard?.scaling.scale ?? 1;
      const {lineIndex} = calcLocation();
      const lineHeight = target.getHeightOfLine(lineIndex) * target.scaleY * posterScale;
      const popupWidth = popUpRef.current?.offsetWidth ?? SUGGESTION_POPUP_MAX_WIDTH;
      const popupHeight = popUpRef.current?.offsetHeight ?? getDefaultPopupHeight();

      let newPosX = posX + canvas.viewportTransform[4];
      let newPosY = posY + canvas.viewportTransform[5];

      if (newPosX <= CORNER_PADDING) {
        newPosX = CORNER_PADDING;
      }
      if (newPosY <= CORNER_PADDING) {
        newPosY = CORNER_PADDING;
      }
      if (newPosX + popupWidth > canvas.width - CORNER_PADDING) {
        newPosX = canvas.width - popupWidth - CORNER_PADDING;
      }
      if (newPosY + popupHeight > canvas.height - CORNER_PADDING) {
        newPosY -= lineHeight + popupHeight;
      }
      return {x: newPosX, y: newPosY};
    }
    return {x: posX, y: posY};
  };

  const calcLocation = (): IndexLocation => {
    const target = popupState.target as Textbox;
    const cursorLocation = target.get2DCursorLocation();
    const {lineIndex} = cursorLocation;
    let charIndex = cursorLocation.charIndex > 0 ? cursorLocation.charIndex - 1 : 0;
    charIndex += target.text.length >= target.selectionStart + 1 && target.searchWordBoundary(target.selectionStart + 1, -1) === target.selectionStart ? 1 : 0;
    return {lineIndex, charIndex};
  };

  const onSelectAllText = (): void => {
    const activeObject = activeCanvas?.getActiveObject();
    if (activeObject && activeObject instanceof Textbox) {
      activeObject.selectAll();
    }
    applyFocusOnTextarea();
    activeCanvas?.requestRenderAll();
  };

  const onCopyFromText = async (): Promise<void> => {
    const activeObject = activeCanvas?.getActiveObject();

    if (activeCanvas && activeObject && activeObject instanceof Textbox) {
      const selectionStart = activeObject.hiddenTextarea ? activeObject.hiddenTextarea.selectionStart : activeObject.selectionStart;
      const selectionEnd = activeObject.hiddenTextarea ? activeObject.hiddenTextarea.selectionEnd : activeObject.selectionEnd;
      await copyToClipboard(activeObject.text.slice(selectionStart, selectionEnd));
      activeObject.selectionStart = selectionEnd;
      activeObject.selectionEnd = selectionEnd;
    }
    applyFocusOnTextarea();
    closeTextSelectionPopup();
  };

  const onCutFromText = async (): Promise<void> => {
    const activeObject = activeCanvas?.getActiveObject();

    if ((activeItem?.isText() || activeItem?.isSlideshow()) && activeCanvas && activeObject && activeObject instanceof Textbox) {
      await copyToClipboard(activeObject.text.slice(activeObject.selectionStart, activeObject.selectionEnd));
      const prevText = activeObject.text;
      const updatedText = prevText.substring(0, activeObject.selectionStart) + prevText.substring(activeObject.selectionEnd, prevText.length);
      activeItem.updateActiveText(updatedText);
      activeObject.selectionEnd = activeObject.selectionStart;
    }
    applyFocusOnTextarea();
    closeTextSelectionPopup();
  };

  const onPasteText = async (e?: React.MouseEvent<HTMLElement>): Promise<void> => {
    const activeObject = activeCanvas?.getActiveObject();

    if ((activeItem?.isText() || activeItem?.isSlideshow()) && activeCanvas && activeObject && activeObject instanceof Textbox) {
      await activeItem.onPasteText(e as unknown as Event);
    }
    applyFocusOnTextarea();
    closeTextSelectionPopup();
  };

  const getTextSelectionPopup = (): ReactElement => {
    return (
      <div className="flex-v-align-center">
        <div className="spacing-p-2 _fit-width flex-row-align-center">
          <Button text={window.i18next.t('pmwjs_cut')} size={Size.XSMALL} type={Type.GHOST} onClick={onCutFromText} />
          <Button text={window.i18next.t('pmwjs_copy')} size={Size.XSMALL} type={Type.GHOST} onClick={onCopyFromText} />
          <Button text={window.i18next.t('pmwjs_paste')} size={Size.XSMALL} type={Type.GHOST} onClick={onPasteText} />
          <Icon
            icon="icon-menu-dots"
            size={IconSize.SIZE_ICON_16}
            type={IconType.GHOST}
            shape={IconShape.SQUARE}
            isSelected={isTextOptionExpanded}
            onClick={(): void => {
              setIsTextOptionExpanded(!isTextOptionExpanded);
            }}
          />
        </div>
        {isTextOptionExpanded ? (
          <div className={`spacing-p-2 ${styles.textPopupMoreOptions}`}>
            <Button text={window.i18next.t('pmwjs_select_all')} size={Size.XSMALL} type={Type.GHOST} onClick={onSelectAllText} />
          </div>
        ) : null}
      </div>
    );
  };

  const getTextNoSelectionPopup = (): ReactElement => {
    return (
      <div className="spacing-p-2 _fit-width flex-row-align-center">
        <Button text={window.i18next.t('pmwjs_select_all')} size={Size.XSMALL} type={Type.GHOST} onClick={onSelectAllText} />
        <Button text={window.i18next.t('pmwjs_paste')} size={Size.XSMALL} type={Type.GHOST} onClick={onPasteText} />
      </div>
    );
  };

  const getSpellCheckSuggestionsPopup = (): ReactElement | null => {
    return (
      <ul className="popUpMenuList spacing-p-2 spacing-m-0">
        {getSuggestionsElement()}
        <li id="popup-btn-option" className="spacing-p-0 flex-h-row">
          <Button
            customClasses={`${styles.ignoreButton} flexbox flex-1 spacing-m-r-2`}
            textClasses={styles.ignoreText}
            type={Type.GHOST}
            icon="icon-ban"
            text={window.i18next.t('pmwjs_ignore')}
            size={Size.MEDIUM}
            onClick={onIgnore}
          />
          <SpellCheckSettingsButton />
        </li>
      </ul>
    );
  };

  const getPopupMenu = (): ReactElement | null => {
    const activeObject = activeCanvas?.getActiveObject();
    if (popupState.openSpellCheckPopUpMenu) {
      return getSpellCheckSuggestionsPopup();
    }
    if (popupState.openTextSelectionPopupMenu) {
      if (activeObject && activeObject instanceof Textbox && activeObject.selectionStart === activeObject.selectionEnd) {
        return getTextNoSelectionPopup();
      }
      return getTextSelectionPopup();
    }
    return null;
  };

  return popupState.openSpellCheckPopUpMenu || popupState.openTextSelectionPopupMenu ? (
    <div className={styles.popup}>
      <div ref={popUpRef} className={`${styles.popUpContainer}`} style={getMenuPosition()}>
        {getPopupMenu()}
      </div>
    </div>
  ) : null;
}
