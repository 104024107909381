import {CommonMethods} from '@PosterWhiteboard/common-methods';
import type {PosterTypeObject} from '@PosterWhiteboard/poster-type/poster-type.types';
import type {DeepPartial} from '@/global';

const TYPE_POSTER_CUSTOM = 'poster-custom';
const TYPE_POSTER_CUSTOM_PRINT = 'poster-custom-print';
const TYPE_POSTER_CUSTOM_USER = 'poster-custom-user';

export class PosterType extends CommonMethods {
  public name = '';
  public displayName = '';
  public description = '';
  public keywords = '';
  public width = 0;
  public height = 0;
  public widthInch = 0;
  public heightInch = 0;
  public widthMm = 0;
  public heightMm = 0;
  public widthCm = 0;
  public heightCm = 0;
  public widthFt = 0;
  public heightFt = 0;
  public dpi = 0;
  public idUserPosterType = 0;
  public rotatable = '0';
  public hasPrintProduct = false;
  public scaleFactors = [];

  constructor(opts: DeepPartial<PosterTypeObject> = {}) {
    super();
    this.copyVals(opts);
  }

  public toObject(): PosterTypeObject {
    return {
      name: this.name,
      displayName: this.displayName,
      description: this.description,
      keywords: this.keywords,
      width: this.width,
      height: this.height,
      widthInch: this.widthInch,
      heightInch: this.heightInch,
      widthMm: this.widthMm,
      heightMm: this.heightMm,
      widthCm: this.widthCm,
      heightCm: this.heightCm,
      widthFt: this.widthFt,
      heightFt: this.heightFt,
      dpi: this.dpi,
      idUserPosterType: this.idUserPosterType,
      rotatable: this.rotatable,
      hasPrintProduct: this.hasPrintProduct,
      scaleFactors: this.scaleFactors,
    };
  }

  public isCustomSize(): boolean {
    return this.name === TYPE_POSTER_CUSTOM || this.name === TYPE_POSTER_CUSTOM_PRINT || this.name.includes(TYPE_POSTER_CUSTOM_USER);
  }
}
