import {Page} from '@PosterWhiteboard/page/page.class';
import {GlobalPosterEditorJqueryElement} from '@Components/poster-editor/poster-editor.types';

export const addNewPage = async (order?: number): Promise<void> => {
  if (!window.posterEditor?.whiteboard?.pages) {
    throw Error('No poster found to add page. Initialize a poster first');
  }

  const pagesInOrder = window.posterEditor.whiteboard.pages.getPagesInOrder();
  const pageOrder = order ?? pagesInOrder?.length;

  const page = await window.posterEditor.whiteboard.pages.addPage(
    {
      background: pagesInOrder[pageOrder - 1].background?.toObject(),
    },
    {pageOrder}
  );

  const pageOffsetTop = Page.getPrePageHTMLContainerForPageId(page.hashedID)?.offsetTop;
  if (window.posterEditor?.elements[GlobalPosterEditorJqueryElement.WHITEBOARD_CONTAINER] && pageOffsetTop) {
    window.posterEditor.elements[GlobalPosterEditorJqueryElement.WHITEBOARD_CONTAINER].animate({scrollTop: pageOffsetTop}, 300);
  }
};
