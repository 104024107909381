import type {ReactElement} from 'react';
import React, {useRef} from 'react';
import type {PanelFooterProps, PanelHeaderProps} from '@Components/panel';
import {Panel, PanelContent} from '@Components/panel';
import {Text, TextSize} from '@Components/text';
import type { IconProps} from '@Components/icon-v2';
import {Icon} from '@Components/icon-v2';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {getUniqueString} from '@Utils/string.util';
import useDefaultPanelClose from '@Panels/hooks/useDefaultPanelClose';
import type {BasePanel} from '@Components/panel/panel.types';
import type {ContainerQueryObject} from '@Hooks/useContainerQuery';
import {useContainerQuery} from '@Hooks/useContainerQuery';
import styles from './message-panel.module.scss';

export enum MESSAGE_TYPE {
  DEFAULT = 'DEFAULT',
  DANGER = 'DANGER',
}

export interface MessagePanelProps extends BasePanel {
  text: string;
  textClassName?: string;
  className?: string;
  showIcon?: boolean;
  icon?: string;
  type?: MESSAGE_TYPE;
  header?: ReactElement<PanelHeaderProps> | null;
  footer?: ReactElement<PanelFooterProps> | null;
  iconProps?: Partial<IconProps>;
  iconContainerStyles?: string;
}

const containerQuery: ContainerQueryObject = {
  small: {
    minWidth: 0,
    maxWidth: 550,
  },
  large: {
    minWidth: 551,
  },
};

function MessagePanel({
  text,
  type = MESSAGE_TYPE.DEFAULT,
  textClassName = '',
  className = '',
  icon = 'icon-exclamation-triangle',
  showIcon = true,
  footer = null,
  header = null,
  iconProps = {},
  iconContainerStyles = '',
  ...props
}: MessagePanelProps): ReactElement {
  const containerRef = useRef<HTMLDivElement>(null);
  const params = useContainerQuery(containerRef, containerQuery);
  const panelId = useRef(props.panelId ?? getUniqueString());
  useDefaultPanelClose(panelId.current);

  const getMessageIconColor = (): string => {
    return type === MESSAGE_TYPE.DANGER ? 'color-danger-100' : 'color-primary-darker';
  };

  const getContent = (): ReactElement => {
    return (
      <PanelContent
        className={`${styles.content} ${type === MESSAGE_TYPE.DANGER ? styles.isDanger : ''} flex-center flex-v-row spacing-p-t-7 spacing-p-b-7 spacing-p-l-12 spacing-p-r-12`}
      >
        {showIcon ? (
          <div className={`${styles.iconWrapper} flexbox spacing-m-r-0 spacing-m-t-2 spacing-p-9 ${iconContainerStyles}`}>
            <Icon icon={icon} className={`${getMessageIconColor()} _unmargin`} size={IconSize.SIZE_ICON_48} type={IconType.NONE} shape={IconShape.NONE} {...iconProps} />
          </div>
        ) : null}
        <Text className={`${textClassName} ${styles.message} spacing-m-t-6`} dangerouslySetInnerHTML size={params.small ? TextSize.XSMALL : TextSize.SMALL} val={text} />
      </PanelContent>
    );
  };

  return <Panel ref={containerRef} className={`${className}`} panelHeader={header} panelContent={getContent()} panelFooter={footer} />;
}

export default MessagePanel;
