import type {ReactElement} from 'react';
import React, {useEffect, useRef} from 'react';
import {GlobalPosterEditorJqueryElement} from '@Components/poster-editor/poster-editor.types';
import {updateHorizontalScrollbarState} from '@Components/poster-editor/poster-editor-reducer';
import styles from './poster-horizontal-scroll.module.scss';
import {useAppDispatch, useAppSelector} from '@/hooks';

export const MARGIN_RIGHT = 20;
const SCROLL_BAR_HEIGHT = '18px';

export function PosterHorizontalScroll(): ReactElement | null {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scale = useAppSelector((state) => {
    return state.posterEditor?.posterObject?.scaling.scale;
  });

  const posterWidth = useAppSelector((state) => {
    return state.posterEditor?.posterObject?.width;
  });

  const isMobileVariant = useAppSelector((state) => {
    return state.posterEditor.isMobileVariant;
  });

  const newCanvasTotalWidth = posterWidth !== undefined && scale !== undefined ? posterWidth * scale : 0;
  const newCanvasTotalWidthWithScrollMargin = newCanvasTotalWidth + MARGIN_RIGHT;
  const whiteboardContainer = window.document.getElementById('poster-whiteboard-container');
  const areScrollBarsVisible = whiteboardContainer ? newCanvasTotalWidth > whiteboardContainer.clientWidth : false;
  const newCanvasVisibleWidth = areScrollBarsVisible && whiteboardContainer ? whiteboardContainer.clientWidth : newCanvasTotalWidth;
  const page = window.posterEditor?.whiteboard?.getCurrentPage();
  const canvas = page?.fabricCanvas;
  const dispatch = useAppDispatch();

  useEffect((): void => {
    dispatch(updateHorizontalScrollbarState(areScrollBarsVisible));
  }, [areScrollBarsVisible]);

  const refreshVisibleCanvas = (): void => {
    if (!scrollContainerRef.current || !whiteboardContainer || !newCanvasTotalWidth) {
      return;
    }

    const scrollableWidth = scrollContainerRef.current.scrollWidth - scrollContainerRef.current.clientWidth;

    const scrollableWidthForMargin = MARGIN_RIGHT;
    const scrollableWidthForCanvas = Math.max(0, scrollableWidth - scrollableWidthForMargin);

    const scrollPercentageForCanvas = scrollableWidthForCanvas ? Math.min(1, scrollContainerRef.current.scrollLeft / scrollableWidthForCanvas) : 0;
    const scrollAmountForMargin = Math.max(0, scrollContainerRef.current.scrollLeft - scrollableWidthForCanvas);
    const scrollPercentageForMargin = scrollableWidthForMargin ? Math.min(1, scrollAmountForMargin / scrollableWidthForMargin) : 0;

    if (canvas) {
      canvas.viewportTransform[4] = -(scrollPercentageForCanvas * (newCanvasTotalWidth - newCanvasVisibleWidth));
      canvas.setViewportTransform(canvas.viewportTransform);
      canvas.requestRenderAll();

      window.posterEditor?.elements[GlobalPosterEditorJqueryElement.WHITEBOARD]?.css('left', `-${scrollPercentageForMargin * MARGIN_RIGHT}px`);
    }
  };

  const onScroll = (): void => {
    if (page && !page.canvasPanZoom.isTouchGestureBeingApplied) {
      refreshVisibleCanvas();
    }
  };

  useEffect(() => {
    if (scrollContainerRef.current && window.posterEditor) {
      window.posterEditor.elements[GlobalPosterEditorJqueryElement.POSTER_HORIZONTAL_SCROLL] = $(scrollContainerRef.current);
    }
  }, []);

  useEffect(() => {
    scrollContainerRef.current?.addEventListener('scroll', onScroll);

    return () => {
      scrollContainerRef.current?.removeEventListener('scroll', onScroll);
    };
  }, [newCanvasTotalWidth, newCanvasTotalWidthWithScrollMargin, newCanvasVisibleWidth, canvas, whiteboardContainer, scrollContainerRef.current]);

  useEffect(() => {
    refreshVisibleCanvas();
  }, [scale]);

  return (
    <div
      style={{height: SCROLL_BAR_HEIGHT}}
      className={`${styles.scrollContainer} ${isMobileVariant ? styles.hideScrollbarContent : ''} ${areScrollBarsVisible ? '' : styles.disablePointer}`}
      ref={scrollContainerRef}
    >
      <div style={{width: `${newCanvasTotalWidthWithScrollMargin}px`, height: SCROLL_BAR_HEIGHT}} />
    </div>
  );
}

export const scrollPosterHorizontalWithDelta = (deltaX: number): void => {
  const htmlElement = window.posterEditor?.elements[GlobalPosterEditorJqueryElement.POSTER_HORIZONTAL_SCROLL]?.get(0);

  if (htmlElement) {
    htmlElement.scrollLeft = Math.max(0, htmlElement.scrollLeft + deltaX);
  }
};
