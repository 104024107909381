import type {ReactElement} from 'react';
import React from 'react';
import {Button, Size, Type} from '@Components/button';
import {addNewPage} from '@Components/poster-editor/library/poster-editor-page.class';
import {ENABLE_PAGES} from '@Components/poster-editor/poster-editor.types';
import styles from './add-new-page-button.module.scss';

export function AddNewPageButton(): ReactElement | null {
  if (!ENABLE_PAGES) {
    return null;
  }

  return (
    <Button
      onClick={(): void => {
        addNewPage().catch((e) => {
          console.error(e);
        });
      }}
      icon="icon-plus"
      type={Type.SECONDARY}
      size={Size.SMALL}
      customClasses={`spacing-m-t-3 ${styles.button}`}
      text={window.i18next.t('pmwjs_new_page')}
    />
  );
}
