import type {Page} from '@PosterWhiteboard/page/page.class';
import type * as Fabric from '@postermywall/fabricjs-2';
import {SCALE_PRECISION} from '@Utils/math.util';
import {cloneAsAlignedImage} from '@Utils/fabric.util';
import {PAGE_WATERMARK_MODE} from '@PosterWhiteboard/page/page-watermark.class';
import {getFontFileUrl} from '@Utils/font.util';
import {fetchAs} from '@Utils/browser.util';
import {prependProtocol} from '@Utils/url.util';
import {config} from '@postermywall/fabricjs-2';

export class PageSVG {
  public page: Page;

  constructor(page: Page) {
    this.page = page;
  }

  /**
   * This function changes items on the poster and makes it unsable to use. This is only used in generation flow
   */
  public async getPosterSvg(pdfSafe = false, enableWatermark = true, enlargeWatermark = false): Promise<string> {
    console.log('calling await convertPosterToSvg_');
    let svg = await this.convertPosterToSvg(pdfSafe, enableWatermark, enlargeWatermark);
    console.log('passed convertPosterToSvg_, calling await onSvgConversion_');
    svg = await this.cleanSVG(svg);
    console.log('passed onSvgConversion_');
    return svg;
  }

  private async convertPosterToSvg(pdfSafe: boolean, enableWatermark: boolean, enlargeWatermark: boolean): Promise<string> {
    let canvas = this.page.fabricCanvas;

    if (pdfSafe) {
      canvas = await this.getPdfSafeCanvas(enableWatermark, enlargeWatermark);
    }
    const oldPrec = this.getFabricFractionDigits();
    this.fabricFractionDigits(SCALE_PRECISION);
    // The params for toSVG are optional. Update the library to remove this ts error
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const svg = canvas.toSVG();
    this.fabricFractionDigits(oldPrec);

    return svg;
  }

  /**
   * Returns the canvas with any items unsupported by pdf or svg as rasterized images.
   * Should be called as the last action before starting generation since rasterized clones might not have
   * all functionality of original
   */
  private async getPdfSafeCanvas(enableWatermark: boolean, enlargeWatermark: boolean): Promise<Fabric.Canvas | Fabric.StaticCanvas> {
    await this.cloneBgImageOrGradient();
    await this.cloneGraphicItems();
    this.page.pageWatermark.setWatermark({
      mode: PAGE_WATERMARK_MODE.DEFAULT,
      showPMW: enableWatermark,
      enlargeWatermark,
    });
    this.page.fabricCanvas.requestRenderAll();

    return this.page.fabricCanvas;
  }

  /**
   * Clones poster background if it's an image or gradient and inserts as a fabric object
   * @returns {Promise<void>}
   * @private
   */
  private async cloneBgImageOrGradient(): Promise<void> {
    const canvas = this.page.fabricCanvas;
    if (!('backgroundImage' in canvas && canvas.backgroundImage)) {
      return;
    }
    const bg = await cloneAsAlignedImage(canvas.backgroundImage, {
      multiplier: this.page.poster.scaling.scale,
    });
    // @ts-ignore
    canvas.backgroundImage = false;
    canvas.add(bg);
  }

  /**
   * Clones any items not safe for pdf/svg generation. Re-inserts all items again to maintain order
   * @returns {Promise<void>}
   * @private
   */
  private async cloneGraphicItems(): Promise<void> {
    const canvas = this.page.fabricCanvas;
    const graphicItems: Array<Fabric.Object | Fabric.Group> = [];

    for (const itemId of this.page.items.getItemIdsInOrder()) {
      const item = this.page.items.itemsHashMap[itemId];
      // eslint-disable-next-line no-await-in-loop
      const fabricObjects = await item.getFabricObjectsForPDF();
      canvas.remove(item.fabricObject);
      graphicItems.push(...fabricObjects);
    }
    canvas.add(...graphicItems);
  }

  private fabricFractionDigits(val: number): void {
    config.NUM_FRACTION_DIGITS = val;
  }

  private getFabricFractionDigits(): number {
    return config.NUM_FRACTION_DIGITS;
  }

  private async cleanSVG(svg: string): Promise<string> {
    let cleanSVG = svg;

    try {
      if (/<text/.test(svg)) {
        cleanSVG = await this.cleanSvgText(cleanSVG);
      }
      if (/<image/.test(svg)) {
        cleanSVG = await this.cleanSvgImage(cleanSVG);
      }
    } catch (e) {
      console.error(e);
      return svg;
    }

    cleanSVG = this.removeCommonPartsBug(cleanSVG);
    cleanSVG = this.addLinkTags(cleanSVG);
    cleanSVG = this.removeUidTags(cleanSVG);
    return cleanSVG;
  }

  private removeUidTags(svg: string): string {
    let cleanSVG = svg;

    for (const [, item] of Object.entries(this.page.items.itemsHashMap)) {
      const {uid} = item;
      const openTagRegex = new RegExp(`<g id="${uid}">`, 'g');
      const closeTagRegex = new RegExp(`</g id="${uid}">`, 'g');
      cleanSVG = cleanSVG.replace(openTagRegex, '');
      cleanSVG = cleanSVG.replace(closeTagRegex, '');
    }
    return cleanSVG;
  }

  /**
   * Uses links on poster to surround corresponding elements with <a> </a> tags
   * pointing to required link
   */
  private addLinkTags(svg: string): string {
    let cleanSVG = svg;
    const links = this.page.getItemLinksMap();
    if (!links) {
      return cleanSVG;
    }
    for (const [, item] of Object.entries(this.page.items.itemsHashMap)) {
      const {uid} = item;
      if (!links[uid]) {
        // eslint-disable-next-line no-continue
        continue;
      }
      let link = links[uid];
      link = prependProtocol(link);

      const openTagRegex = new RegExp(`<g id="${uid}">`, 'g');
      // eslint-disable-next-line no-useless-escape
      const closeTagRegex = new RegExp(`<\/g id="${uid}">`, 'g');
      const openReplacement = `<g id="${uid}"><a href="${link}">`;
      const closeReplacement = `</a></g id="${uid}">`;
      cleanSVG = cleanSVG.replace(openTagRegex, openReplacement);
      cleanSVG = cleanSVG.replace(closeTagRegex, closeReplacement);
    }
    return cleanSVG;
  }

  /**
   * Removes some non-svg specific text left by fabric.js conversion
   */
  private removeCommonPartsBug(svg: string): string {
    const bugRegex = /,COMMON_PARTS,/g;
    return svg.toString().replace(bugRegex, '');
  }

  /**
   * Handles any functionality required on <image> tags present in svg
   * @returns {Promise<void>}
   */
  private async cleanSvgImage(svg: string): Promise<string> {
    const images = svg.split('<image');
    const urlImages = await Promise.all(
      images.map((image) => {
        return this.fetchAndEmbedImage(image);
      })
    );
    return urlImages.join('<image');
  }

  private async fetchAndEmbedImage(image: string): Promise<string> {
    const tagContentRegex = /.*?>/i;
    const urlRegex = /http[s]?:\/\/.*?"/i;

    const matches = image.match(tagContentRegex);
    if (!matches || !urlRegex.test(matches[0])) {
      return image;
    }
    let webUrl = '';
    try {
      const hrefRegex = /href="\S*?"/i;
      const href = matches[0].match(hrefRegex);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      webUrl = href[0].match(urlRegex)[0].replace(/['"]/g, '');
      const imgBlob = await fetchAs(webUrl, 'blob');
      const imgUrl = await this.blobToBase64Url(imgBlob as Blob);
      return image.replace(hrefRegex, `href="${imgUrl}"`);
    } catch (_) {
      console.error('failed to convert image ', webUrl);
      return image;
    }
  }

  private async cleanSvgText(svg: string): Promise<string> {
    let cleanSVG = svg;
    const fontFamilies = this.page.poster.getFontsOnPoster(true);
    cleanSVG = this.fixFontFamilyNames(cleanSVG);
    cleanSVG = await this.addFontDefs(fontFamilies, cleanSVG);
    return cleanSVG;
  }

  /**
   * Removes extra quotes left in family name during svg conversion
   */
  private fixFontFamilyNames(svg: string): string {
    const familyAttrRegex = /font-family=".*?"/gi;
    const matches = svg.match(familyAttrRegex);
    let cleanSVG = svg;

    if (!matches) {
      return cleanSVG;
    }

    for (const match of matches) {
      const familyName = match
        .replace(/font-family=/gi, '')
        .replace(/['"]/gi, '')
        .trim();
      const replacement = `font-family="${familyName}"`;
      cleanSVG = cleanSVG.replace(match, replacement);
    }

    return cleanSVG;
  }

  /**
   * Embeds <style> tag for all fonts on poster into the <defs> tag of svg
   * Styles include: family name, base64 src, external src
   */
  private async addFontDefs(fontFamilies: Array<string>, svg: string): Promise<string> {
    const styleOpenTag = `<style>`;
    const styleCloseTag = `</style>`;
    const defsCloseTagRegex = /<\/defs>/;
    const styles = await this.makeFontStyles(fontFamilies);

    let style = '';
    styles.forEach((fontFace) => {
      style += fontFace;
      return style;
    });
    style = styleOpenTag + style + styleCloseTag;
    // eslint-disable-next-line no-useless-escape
    const defsReplacement = `${style}\n<\/defs>`;
    return svg.replace(defsCloseTagRegex, defsReplacement);
  }

  /**
   * Handles creation of font-face styles for given fonts
   * @param {string[]} fontFamilies each object in array contains family property for font-family name
   * @returns {Promise<string[]>} array containg @font-face styles
   * @private
   */
  private async makeFontStyles(fontFamilies: Array<string>): Promise<Array<string>> {
    const FONT_PREFERENCES = ['woff', 'truetype', 'woff2'];

    const faces = fontFamilies.map(async (family) => {
      try {
        const fontUrl = await getFontFileUrl(family, FONT_PREFERENCES);
        return await this.makeFontFace(fontUrl, family);
      } catch (e) {
        console.error(e);
        return '';
      }
    });
    return Promise.all(faces);
  }

  private async makeFontFace(fontUrl: string, fontFamily: string): Promise<string> {
    const LOCAL_FONT_DIR = 'assets/css/webfonts/';
    let newFontUrl = fontUrl;
    try {
      if (!/http/.test(newFontUrl)) {
        newFontUrl = this.setTrailingSlash(window.PMW.BASE_URL) + LOCAL_FONT_DIR + newFontUrl;
      }
      const fontBlob = (await fetchAs(fontUrl, 'blob')) as Blob;
      const base64Url = await this.blobToBase64Url(fontBlob);
      return `@font-face {\n      font-family: ${fontFamily};\n      src: url('${base64Url}'),\n      url('${newFontUrl}');\n    }\n`;
    } catch (e) {
      console.error(e);
      return '';
    }
  }

  private setTrailingSlash(url: string): string {
    const sep = url.lastIndexOf('/') === url.length - 1 ? '' : '/';
    return url + sep;
  }

  private blobToBase64Url(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = (): void => {
        resolve(reader.result as string);
      };
      reader.onerror = (): void => {
        return reject(reader.error);
      };
      reader.readAsDataURL(blob);
    });
  }
}
