import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type * as Fabric from '@postermywall/fabricjs-2';
import type {FabricPositionalParams} from '@Utils/fabric.util';

interface WordPosition {
  start: number;
  end: number;
}

interface StateProps {
  openSpellCheckPopUpMenu: boolean;
  openTextSelectionPopupMenu: boolean;
  popUpPosition?: FabricPositionalParams | undefined;
  target: any | undefined;
  words?: Array<string>;
  wordPosition?: WordPosition | undefined;
}

interface SpellCheckPopupMenuProps {
  openSpellCheckPopUpMenu: boolean;
  popUpPosition?: FabricPositionalParams | undefined;
  target: any | undefined;
  words?: Array<string>;
  wordPosition?: WordPosition | undefined;
}

interface TextSelectionPopupProps {
  openTextSelectionPopupMenu: boolean;
  target: any | undefined;
  wordPosition?: WordPosition | undefined;
}

const initialState: StateProps = {
  openSpellCheckPopUpMenu: false,
  openTextSelectionPopupMenu: false,
  popUpPosition: undefined,
  target: undefined,
  words: [],
  wordPosition: undefined,
};

const TextItemPopUpMenu = createSlice({
  name: 'SpellCheckPopUpMenu',
  initialState,
  reducers: {
    updateSpellCheckPopUpOpenState: (state: StateProps, action: PayloadAction<boolean>) => {
      state.openSpellCheckPopUpMenu = action.payload;
    },
    updateTextSelectionPopUpOpenState: (state: StateProps, action: PayloadAction<boolean>) => {
      state.openTextSelectionPopupMenu = action.payload;
    },
    updateSpellCheckPopUpTargetAndWords: (state: StateProps, action: PayloadAction<SpellCheckPopupMenuProps>) => {
      state.openSpellCheckPopUpMenu = action.payload.openSpellCheckPopUpMenu;
      state.target = action.payload.target as Fabric.Textbox;
      state.words = action.payload.words;
      state.wordPosition = action.payload.wordPosition;
    },
    updateTextSelectionPopup: (state: StateProps, action: PayloadAction<TextSelectionPopupProps>) => {
      state.openTextSelectionPopupMenu = action.payload.openTextSelectionPopupMenu;
      state.target = action.payload.target as Fabric.Textbox;
      state.wordPosition = action.payload.wordPosition;
      state.openSpellCheckPopUpMenu = false;
    },
  },
});

export const {updateSpellCheckPopUpOpenState, updateTextSelectionPopUpOpenState, updateSpellCheckPopUpTargetAndWords, updateTextSelectionPopup} = TextItemPopUpMenu.actions;
export const TextItemPopUpMenuReducer = TextItemPopUpMenu.reducer;
