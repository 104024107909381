import type {MouseEvent, ReactElement} from 'react';
import React from 'react';
import type {ControlledListItemType} from '@Components/controlled-list/controlled-list.types';
import {CONTROLLED_LIST_ITEM_TYPE} from '@Components/controlled-list/controlled-list.types';
import type {DraggableProvided, DragStart, DroppableProvided, DropResult} from 'react-beautiful-dnd';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {ControlledListItemType1} from '@Components/controlled-list/components/controlled-list-item-type-1';
import {ControlledListItemType2} from '@Components/controlled-list/components/controlled-list-item-type-2';
import {ControlledListItemType3} from '@Components/controlled-list/components/controlled-list-item-type-3';
import {ControlledListItemType4} from '@Components/controlled-list/components/controlled-list-item-type-4';
import {ControlledListItemType5} from '@Components/controlled-list/components/controlled-list-item-type-5';
import {ControlledListHeadingItem} from '@Components/controlled-list/components/controlled-list-heading-item';
import {ControlledListItemType7} from '@Components/controlled-list/components/controlled-list-item-type-7';
import {ControlledListItemType6} from '@Components/controlled-list/components/controlled-list-item-type-6';
import {ControlledListDividerItem} from '@Components/controlled-list/components/controlled-list-divider-item';
import {ControlledListItemTypeButton} from '@Components/controlled-list/components/controlled-list-item-type-button';
import {ControlledListItemType8} from '@Components/controlled-list/components/controlled-list-item-type-8';

interface ControlledListProps {
  id?: string;
  items: Array<ControlledListItemType>;
  className?: string;
  /**
   * only supported for type 1 and 6
   */
  isDraggable?: boolean;
  /**
   * only supported for controlled type 3 at the moment
   */
  scrollIntoViewIfSelected?: boolean;

  onClick?(listItemId: string, event?: MouseEvent): void;

  onDragStart?(sourceIndex: number): void;

  onDragEnd?(sourceIndex: number, destinationIndex?: number): void;

  scrollIntoViewBlockProperty?: ScrollLogicalPosition;
}

export function ControlledList({
  id = '',
  className = '',
  isDraggable = false,
  onClick = (): void => {},
  onDragStart = (): void => {},
  onDragEnd = (): void => {},
  scrollIntoViewIfSelected = false,
  scrollIntoViewBlockProperty = 'center',
  ...props
}: ControlledListProps): ReactElement {
  const prepItems = (): Array<ReactElement> => {
    const itemsToDisplay: Array<ReactElement> = [];
    props.items.map((item, index) => {
      let handlers = {};
      if (item.type !== CONTROLLED_LIST_ITEM_TYPE.HEADING && item.type !== CONTROLLED_LIST_ITEM_TYPE.DIVIDER) {
        handlers = {
          onClick: (itemId: string, e: React.MouseEvent<HTMLElement>): void => {
            if (item.onClick) {
              item.onClick(item.id, e);
            }
            onClick(item.id, e);
          },
        };
      }

      let showSelectionCheckmark = {};
      if ('showSelectionCheckmark' in item) {
        showSelectionCheckmark = {
          showSelectionCheckmark: item.showSelectionCheckmark,
        };
      }

      switch (item.type) {
        case CONTROLLED_LIST_ITEM_TYPE.DEFAULT_1:
          if (isDraggable) {
            itemsToDisplay.push(
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided: DraggableProvided): ReactElement => {
                  return (
                    <ControlledListItemType1
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      id={item.id}
                      key={item.id}
                      className={item.className}
                      text={item.text}
                      {...handlers}
                      isDraggable={isDraggable}
                      subText={item.subText}
                      loading={item.loading}
                      showEditIcon={item.showEditIcon}
                      isSelected={item.isSelected}
                    />
                  );
                }}
              </Draggable>
            );
          } else {
            itemsToDisplay.push(
              <ControlledListItemType1
                id={item.id}
                key={item.id}
                className={item.className}
                text={item.text}
                isDraggable={isDraggable}
                subText={item.subText}
                loading={item.loading}
                showEditIcon={item.showEditIcon}
                isSelected={item.isSelected}
                {...handlers}
              />
            );
          }
          break;
        case CONTROLLED_LIST_ITEM_TYPE.DEFAULT_2:
          itemsToDisplay.push(
            <ControlledListItemType2
              id={item.id}
              className={item.className}
              key={item.id}
              text={item.text}
              subText={item.subText}
              thumbnail={item.thumbnail}
              isSelected={item.isSelected}
              hasPulsatingDot={item.hasPulsatingDot}
              textClasses={item.textClasses}
              {...handlers}
            />
          );
          break;
        case CONTROLLED_LIST_ITEM_TYPE.DEFAULT_3:
          itemsToDisplay.push(
            <ControlledListItemType3
              id={item.id}
              className={item.className}
              key={item.id}
              text={item.text}
              subText={item.subText}
              subItems={item.subItems}
              icon={item.icon}
              listSize={item.listSize}
              onClickSubItem={(subItemId, state): void => {
                if (item.onClickSubItem) {
                  item.onClickSubItem(subItemId, state);
                }
              }}
              isSelected={item.isSelected}
              actions={item.actions}
              containsDangerouslySetText={item.containsDangerouslySetText}
              {...handlers}
              {...showSelectionCheckmark}
              scrollIntoViewIfSelected={scrollIntoViewIfSelected}
              scrollIntoViewBlockProperty={scrollIntoViewBlockProperty}
            />
          );
          break;
        case CONTROLLED_LIST_ITEM_TYPE.DEFAULT_4:
          itemsToDisplay.push(<ControlledListItemType4 id={item.id} className={item.className} key={item.id} text={item.text} {...handlers} isSelected={item.isSelected} />);
          break;
        case CONTROLLED_LIST_ITEM_TYPE.DEFAULT_5:
          itemsToDisplay.push(
            <ControlledListItemType5 id={item.id} className={item.className} key={item.id} text={item.text} pill={item.pill} isSelected={item.isSelected} {...handlers} />
          );
          break;
        case CONTROLLED_LIST_ITEM_TYPE.BUTTON:
          itemsToDisplay.push(
            <ControlledListItemTypeButton
              id={item.id}
              className={item.className}
              key={item.id}
              text={item.text}
              isSelected={false}
              onClick={item.onClick}
              buttonProps={item.buttonProps}
            />
          );
          break;
        case CONTROLLED_LIST_ITEM_TYPE.DEFAULT_6:
          itemsToDisplay.push(
            <ControlledListItemType6
              id={item.id}
              className={item.className}
              key={item.id}
              text={item.text}
              color={item.color}
              size={item.size}
              checkboxType={item.checkboxType}
              isDisabled={item.isDisabled}
              isSelected={item.isSelected}
              {...handlers}
            />
          );
          break;
        case CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7:
          itemsToDisplay.push(
            <ControlledListItemType7
              id={item.id}
              className={item.className}
              key={item.id}
              text={item.text}
              color={item.color}
              icon={item.icon}
              iconClasses={item.iconClasses}
              shortcut={item.shortcut}
              isDisabled={item.isDisabled}
              isSelected={item.isSelected}
              rightIcon={item.rightIcon}
              rightIconClassName={item.rightIconClassName}
              textClasses={item.textClasses}
              listSize={item.listSize}
              onRightIconClick={(idItem): void => {
                if (item.rightIcon && item.onRightIconClick) {
                  item.onRightIconClick(idItem);
                }
              }}
              {...handlers}
            />
          );
          break;
        case CONTROLLED_LIST_ITEM_TYPE.DEFAULT_8:
          itemsToDisplay.push(
            <ControlledListItemType8
              id={item.id}
              className={item.className}
              key={item.id}
              text={item.text}
              icon={item.icon}
              iconClasses={item.iconClasses}
              isDisabled={item.isDisabled}
              isSelected={item.isSelected}
              rightIcon={item.rightIcon}
              rightIconClassName={item.rightIconClassName}
              textClasses={item.textClasses}
              listSize={item.listSize}
              onRightIconClick={(idItem): void => {
                if (item.rightIcon && item.onRightIconClick) {
                  item.onRightIconClick(idItem);
                }
              }}
              {...handlers}
            />
          );
          break;
        case CONTROLLED_LIST_ITEM_TYPE.HEADING:
          itemsToDisplay.push(<ControlledListHeadingItem id={item.id} className={item.className} key={item.id} text={item.text} />);
          break;
        case CONTROLLED_LIST_ITEM_TYPE.DIVIDER:
          itemsToDisplay.push(<ControlledListDividerItem id={item.id} className={item.className} key={item.id} />);
          break;
        default:
          throw new Error('Invalid type for controlled list');
      }

      return itemsToDisplay;
    });

    return itemsToDisplay;
  };

  const displayDraggableList = (): ReactElement => {
    return (
      <DragDropContext
        onDragStart={(e: DragStart): void => {
          onDragStart(e.source.index);
        }}
        onDragEnd={(e: DropResult): void => {
          onDragEnd(e.source.index, e?.destination?.index);
        }}
      >
        <Droppable droppableId={id}>
          {(provided: DroppableProvided): ReactElement => {
            return (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {displayList()}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    );
  };

  const displayList = (): ReactElement => {
    return (
      <ul key={id} className={className}>
        {prepItems()}
      </ul>
    );
  };

  return isDraggable ? displayDraggableList() : displayList();
}
