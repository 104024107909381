import {snapItemToLines} from '@PosterWhiteboard/page/snap-items';
import type {CanvasEvents, TMat2D} from '@postermywall/fabricjs-2';
import {Canvas} from '@postermywall/fabricjs-2';
import type {Page} from './page.class';

interface BleedLine {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
}

const BLEED_STROKE = '#3FBCE8';
const BLEED_LINE_WIDTH = 2;
const SNAPPING_THRESHOLD = 4;

export class RenderBleed {
  public page: Page;
  private canvasCleared = false;
  private viewportTransform: TMat2D;

  constructor(page: Page) {
    this.page = page;
    this.viewportTransform = this.page.fabricCanvas.viewportTransform;
    if (this.page.fabricCanvas instanceof Canvas) {
      this.initAligningGuidelines();
    }
  }

  private initAligningGuidelines(): void {
    this.page.fabricCanvas.on('mouse:down', this.onMouseDown.bind(this));
    this.page.fabricCanvas.on('object:moving', this.onObjectMoving.bind(this));
    this.page.fabricCanvas.on('before:render', this.beforeRender.bind(this));
    this.page.fabricCanvas.on('after:render', this.afterRender.bind(this));
  }

  private afterRender(): void {
    this.drawBleedLines();
  }

  private beforeRender(): void {
    if (this.page.fabricCanvas instanceof Canvas && this.page.fabricCanvas.contextTop) {
      this.page.fabricCanvas.contextTopDirty = true;
      this.canvasCleared = true;
    }
  }

  private onMouseDown(): void {
    this.viewportTransform = this.page.fabricCanvas.viewportTransform;
  }

  private drawBleedLines(): void {
    if (this.page.poster.bleed.enabled && this.canvasCleared) {
      const bleedLines = this.getBleedLines();
      const ctx = this.page.fabricCanvas.contextContainer;

      for (const bleedLine of bleedLines) {
        ctx.save();
        ctx.strokeStyle = BLEED_STROKE;
        ctx.lineWidth = BLEED_LINE_WIDTH;
        ctx.beginPath();
        ctx.moveTo(bleedLine.x1, bleedLine.y1);
        ctx.lineTo(bleedLine.x2, bleedLine.y2);
        ctx.stroke();
        ctx.restore();
      }
      this.canvasCleared = false;
    }
  }

  private getBleedLines(): Array<BleedLine> {
    const lines = [];
    const size = this.page.poster.bleed.getValInPixel() * this.page.fabricCanvas.getZoom();
    const width = this.page.poster.getCanvasTotalWidth();
    const height = this.page.poster.getCanvasTotalHeight();

    lines.push({
      x1: size + this.page.fabricCanvas.viewportTransform[4],
      x2: size + this.page.fabricCanvas.viewportTransform[4],
      y1: size + this.page.fabricCanvas.viewportTransform[5],
      y2: height - size + this.page.fabricCanvas.viewportTransform[5],
    });
    lines.push({
      x1: width - size + this.page.fabricCanvas.viewportTransform[4],
      x2: width - size + this.page.fabricCanvas.viewportTransform[4],
      y1: size + this.page.fabricCanvas.viewportTransform[5],
      y2: height - size + this.page.fabricCanvas.viewportTransform[5],
    });
    lines.push({
      y1: size + this.page.fabricCanvas.viewportTransform[5],
      y2: size + this.page.fabricCanvas.viewportTransform[5],
      x1: size + this.page.fabricCanvas.viewportTransform[4],
      x2: width - size + this.page.fabricCanvas.viewportTransform[4],
    });
    lines.push({
      y1: height - size + this.page.fabricCanvas.viewportTransform[5],
      y2: height - size + this.page.fabricCanvas.viewportTransform[5],
      x1: size + this.page.fabricCanvas.viewportTransform[4],
      x2: width - size + this.page.fabricCanvas.viewportTransform[4],
    });

    return lines;
  }

  private onObjectMoving(e: CanvasEvents['object:moving']): void {
    if (this.page.poster.bleed.enabled && this.page.fabricCanvas instanceof Canvas) {
      const activeObject = e.target;
      if (!activeObject) {
        return;
      }
      const transform = this.page.fabricCanvas._currentTransform;

      if (!transform) return;

      snapItemToLines(activeObject, this.getBleedLines(), SNAPPING_THRESHOLD, this.viewportTransform, this.page.fabricCanvas.getZoom());
    }
  }

  private isInRange(value1: number, value2: number): boolean {
    const roundedValue1 = Math.round(value1);
    const roundedValue2 = Math.round(value2);
    for (let i = roundedValue1 - SNAPPING_THRESHOLD, len = roundedValue1 + SNAPPING_THRESHOLD; i <= len; i++) {
      if (i === roundedValue2) {
        return true;
      }
    }
    return false;
  }
}
