/**
 * This file contains asyncThunk functions for communicating with the backend for font-related operations
 * https://redux-toolkit.js.org/api/createAsyncThunk
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 */

import {createAsyncThunk} from "@reduxjs/toolkit";
export const getPMWFontsVersion = createAsyncThunk(
    'getPMWFontsVersion',
    async () => await PMW.readLocal('font/getPMWFontsVersion')
);


export const getUserUploadedFonts = createAsyncThunk(
    'getUserFonts',
    async () => await PMW.readLocal('font/getUserFontsForUser')
);

export const getUserFacingFonts = createAsyncThunk(
    'getUserFacingFonts',
    async () => await PMW.readLocal('font/getUserFacingFonts')
);

export const fetchLanguageScripts = createAsyncThunk(
    'fetchLanguageScripts',
    async () => await PMW.readLocal('font/getFontLanguageScripts')
);